import React, { useEffect } from "react";
import userphoto from "../../assets/images/myaccount/Ellipse 9.webp";

import { sideBarBtnList } from "./Helper";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateProvider } from "./StateContext";
import { formatNumber } from "../../utils/CommonFunction";

const Sidebar = () => {
  //FOR GETTING PATH
  const location = useLocation();
  //FOR REDIRECT TO PATH
  const navigate = useNavigate();
  //SHOWDATA STATES GETTING FROM PROVIDER
  const { showData, setShowData, setShowFriendData } = useStateProvider(); //OPEN SIDEBAR
  // OVERFLOW HIDDEN WHEN SIDEBAR OPEN
  useEffect(() => {
    if (showData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showData]);

  // ============== NAVITAGE TABES ==================
  const onNavigateHandler = (value) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    navigate(`/${value}`);
    setShowData(false);
    setShowFriendData(false);
  };

  return (
    <>
      {/* USER-IMAGE*/}

      <div className=" min-[1440px]:pt-8">
        <img
          className="max-w-[56px] rounded-full mx-auto"
          src={userphoto}
          alt="user"
        />
        {/* USER-DATA*/}
        <h2 className="text-center ff_poppins font-normal text-white sm:text-[18px] text-[15px] mt-[10px] pb-[10px]">
          Floyd Miles
        </h2>
        <div className="border-b border-solid border-[#FFFFFF20] flex justify-between items-center mt-[10px] pb-[10px] mx-[5px] px-[5px]">
          <h3 className="ff_poppins font-normal text-white text-[12px] sm:text-[14px] opacity-80">
            Subscriptions
          </h3>
          <h3 className="ff_poppins font-normal text-white text-[12px] sm:text-[14px] opacity-80">
            {formatNumber(100000)}
          </h3>
        </div>
        <div className="border-b border-solid border-[#FFFFFF20] flex justify-between items-center mt-[10px] pb-[10px] mx-[5px] px-[5px]">
          <h3 className="ff_poppins font-normal text-white text-[12px] sm:text-[14px] opacity-80">
            Uploads
          </h3>
          <h3 className="ff_poppins font-normal text-white text-[12px] sm:text-[14px] opacity-80">
            {formatNumber(2500)}
          </h3>
        </div>
        <div className="border-b border-solid border-[#FFFFFF20] flex justify-between items-center mt-[10px] pb-[10px] mx-[5px] px-[5px]">
          <h3 className="ff_poppins font-normal text-white text-[12px] sm:text-[14px] opacity-80">
            Likes
          </h3>
          <h3 className="ff_poppins font-normal text-white text-[12px] sm:text-[14px] opacity-80">
            {formatNumber(1200000)}
          </h3>
        </div>
        {/* =================== SIDE NAV LIKES =========== */}
        <div className="pt-[15px]">
          {sideBarBtnList.map((value, index) => (
            <button
              key={index}
              onClick={() => onNavigateHandler(value.link)}
              className={`flex gap-2 items-center py-[10px] opacity-70 mb-[10px] ps-[11px] hover:opacity-100 account_tabs w-full duration-200 border-l-2 border-solid hover:border-[#FFFFFF40] border-transparent ${
                location.pathname === `/${value.link}`
                  ? "account_tabs_active !opacity-100 !border-[#FFFFFF40]"
                  : ""
              }`}>
              {value.icon}{" "}
              <span className="ff_poppins font-normal text-[13px] sm:text-[16px] text-white">
                {value.title}
              </span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
