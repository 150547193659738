import { createContext, useContext, useState } from "react";

const StateProviderContext = createContext();

export function useStateProvider() {
  return useContext(StateProviderContext);
}
export default function StateManagmentProvider({ children }) {
  const [openComment, setOpenComment] = useState(false);
  const [activeTab, setActiveTab] = useState("All"); // State to manage active tab index
  const [showTabs, setShowTabs] = useState("myaccount");
  const [showFriendData, setShowFriendData] = useState(false);
  const [communitieSidebarData, setCommunitieSidebarData] = useState(false);
  // =========== navBarToggle ===========
  const [showData, setShowData] = useState(false);
  // ============ VIDEO CARD DATA =================
  const [cardData, setCardData] = useState("");

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [activeArrow, setActiveArrow] = useState("right");

  const [likesAndDislikes, setLikesAndDislikes] = useState({
    item1: { likes: 0, dislikes: 0, liked: false },
  });

  //PASS VALUE TO COMPONENTS
  const value = {
    setCardData,
    cardData,
    showData,
    setShowData,
    activeTab,
    setActiveTab,
    openComment,
    setOpenComment,
    showTabs,
    setShowTabs,
    videoUrl,
    setVideoUrl,
    isVideoPlaying,
    setIsVideoPlaying,
    likesAndDislikes,
    setLikesAndDislikes,
    showAll,
    setShowAll,
    activeArrow,
    setActiveArrow,
    showFriendData,
    setShowFriendData,
    communitieSidebarData,
    setCommunitieSidebarData,
  };
  return (
    <StateProviderContext.Provider value={value}>
      {children}
    </StateProviderContext.Provider>
  );
}
