import React, { useEffect } from "react";
import Faq from "../common/Faq";
import BackToTop from "../common/BackToTop";
import HeroCategory from "./HeroCategory";

import MostRatedMovie from "../home/MostRatedMovies";
import {
  adventureSlider,
  comedieSlider,
  indianTvShowSlider,
  mostPopularSlider,
  mostRatedSlider,
  romanceSlider,
} from "../common/Helper";
import CategorySlider from "./CategorySlider";
import popular_reel_position_img from "../../assets/images/mostPopular/popular-position-img.png";
import adventure_reel_img from "../../assets/images/adventureSliderImages/adventure-reel-img.png";
import { CommonXmildIcon } from "../common/Icon";

const Category = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  return (
    <>
      <div className="relative">
        <div className="common_bg_gradient_color  max-w-[376px] h-[376px] rounded-[376px] blur-[250px] opacity-70 absolute start-[-10%] top-[-10%] w-full hidden md:block"></div>

        <HeroCategory />
      </div>

      <div className="py-12 xl:py-[80px]  relative">
        {/* REEL IMAGE POSITION */}
        <img
          className=" absolute end-0 top-0 z-0 hidden lg:block"
          src={popular_reel_position_img}
          alt="popular_reel_position_img"
        />
        <h2 className=" text-center ff_poppins font-semibold text-[30px] md:text-[40px] text-white">
          Most <span className="common-text-gradient">Popular</span>
        </h2>
        <CategorySlider categorySliderData={mostPopularSlider} />
      </div>
      <div className="py-12 xl:py-[80px]  relative">
        {/* SHOT TAKE ACTION IMAGE */}
        <span className="common_bg_gradient_color w-[181px] h-[181px] rounded-[343px] blur-[131px] absolute right-0 bottom-0 -z-10 hidden lg:block"></span>
        <span className=" absolute right-0 bottom-[80px] pe-5 hidden xl:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        <span className="common_bg_gradient_color w-[181px] h-[181px] rounded-[343px] blur-[131px] absolute left-0 top-0 -z-10 hidden lg:block"></span>
        <span className=" absolute left-[20px] top-[0px] pe-14 hidden lg:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        <h2 className="text-center ff_poppins font-semibold text-[30px] md:text-[40px] text-white mb-0">
          Indian TV
          <span className="common-text-gradient"> Shows</span>
        </h2>
        <CategorySlider categorySliderData={indianTvShowSlider} />
      </div>
      <div className="py-12 xl:py-[80px]  relative z-10">
        {/* REEL POSITION IMAGE  */}
        <img
          className=" absolute left-[0px] top-[0px] w-[270px]  hidden lg:block"
          src={adventure_reel_img}
          alt="adventure_reel_img"
        />
        <span className="common_bg_gradient_color w-[181px] h-[181px] rounded-[343px] blur-[131px] absolute right-0 top-0 -z-10 hidden lg:block"></span>
        <span className="common_bg_gradient_color w-[181px] h-[181px] rounded-[343px] blur-[131px] absolute left-0 bottom-0 -z-10 hidden lg:block"></span>
        <h2 className=" text-center ff_poppins font-semibold text-[30px] md:text-[40px] text-white mb-0">
          TV action &<span className="common-text-gradient"> Adventure</span>
        </h2>
        <CategorySlider categorySliderData={adventureSlider} />
      </div>
      <MostRatedMovie
        btn="Join Now"
        datadisplay="hidden"
        mapData={mostRatedSlider}
      />
      <div className="pt-12 xl:pt-[50px] pb-12 xl:pb-[80px] relative">
        {/* SHOOT TAKE ACTION IMAGE POSITION */}
        <span className=" absolute right-0 bottom-[80px] pe-5 hidden xl:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        <span className=" absolute left-[20px] top-[0px] pe-14 hidden lg:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        <h2 className=" text-center ff_poppins font-semibold text-[30px] md:text-[40px] text-white mb-0">
          <span className="common-text-gradient">Romance </span>
          TV Shows
        </h2>
        <CategorySlider categorySliderData={romanceSlider} />
      </div>
      <div className="py-12 xl:py-[80px] relative">
        {/* REEL IMAGE BY POSITION */}
        <img
          className="absolute left-[0px] top-[0px] w-[270px] 2xl:w-[320px] hidden lg:block"
          src={adventure_reel_img}
          alt="adventure_reel_img"
        />
        <h2 className=" text-center ff_poppins font-semibold text-[30px] md:text-[40px] text-white mb-0">
          TV <span className="common-text-gradient"> Comedies</span>
        </h2>
        <CategorySlider categorySliderData={comedieSlider} />
      </div>
      <Faq />
      <BackToTop />
    </>
  );
};

export default Category;
