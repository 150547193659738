import React, { useEffect } from "react";
import WatchVideos from "./WatchVideos";
import FanFavorite from "./FanFavorite";
import FeaturedToday from "./FeaturedToday";
import MostRatedMovie from "./MostRatedMovies";
import ReviewsRating from "./ReviewsRating";
import Faq from "../common/Faq";
import RecentlyViewed from "./RecentlyViewed";
import BackToTop from "../common/BackToTop";
import { mostRatedSliderHome } from "../common/Helper";
import HomeHero from "./HomeHero";
const Landing = () => {
  //SCROLLTOP FUNCTION WITH BEHAVIOR SMOOTHly
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  return (
    <>
      <div>
        <div className="relative min-h-screen  flex flex-col">
          <div className="common_bg_gradient_color max-w-[376px] h-[376px] rounded-[376px] blur-[250px] opacity-70 absolute start-[-10%] top-[-10%] w-full hidden md:block -z-10"></div>

          <div className="  flex justify-center items-center">
            <HomeHero />
          </div>
        </div>
        <WatchVideos />
        <FanFavorite />
        <MostRatedMovie
          btn="Download now"
          datadisplay="block"
          mapData={mostRatedSliderHome}
        />
        <FeaturedToday />
        <ReviewsRating />
        <RecentlyViewed />
        <Faq />
        <BackToTop />
      </div>
    </>
  );
};

export default Landing;
