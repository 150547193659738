import React from "react";

import Faq from "../common/Faq";
import AccountSlider from "./AccountSlider";
import { playlistLibrary } from "../common/Helper";
const HistoryAccount = () => {
  return (
    <>
      <div className="bg-my_account_bg bg-cover relative min-[1440px]:pt-[70px]">
        <div className="bottom_blur_hero w-full h-[50px] absolute bottom-0 left-0"></div>
        {/* DEFINE HEADER  */}

        <div className="container md:max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto flex sm:pb-[144px] pb-16 justify-start sm:px-4 lg:px-0 px-0 relative min-h-[50vh]">
          <div className=" sm:pt-[26px] pt-5 tab_data w-full">
            {/* PLAYLIST LIBRARY SLIDER   */}
            <AccountSlider
              heading={"History"}
              accountSliderData={playlistLibrary}
              mostRankedSliderArrow={"hidden"}
            />
          </div>
        </div>
        <div className="sm:pt-[60px] pt-5">
          <Faq />
        </div>
      </div>
    </>
  );
};
export default HistoryAccount;
