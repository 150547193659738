import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./NotFound";
import Library from "../myaccount/Library";
import MyReview from "../myaccount/MyReview";
import HistoryAccount from "../myaccount/HistoryAccount";
import Subscription from "../myaccount/Subscription";
import TrendingAccount from "../myaccount/TrendingAccount";
import AccountDetail from "../myaccount/AccountDetail";
import ProductDetails from "../product-details/ProductDetails";
import Landing from "../home/Landing";
import Category from "../category/Category";
import SignOut from "../myaccount/SignOut";
import MyFriends from "../myaccount/MyFriends";

const RmsRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/category" element={<Category />} />
        <Route path="/product-details" element={<ProductDetails />} />
        <Route path="/library" element={<Library />} />
        <Route path="/my-reviews" element={<MyReview />} />
        <Route path="/history" element={<HistoryAccount />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/trending" element={<TrendingAccount />} />
        <Route path="/account-detail" element={<AccountDetail />} />
        <Route path="/sign-out" element={<SignOut />} />
        <Route path="/my-friends" element={<MyFriends />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default RmsRouter;
