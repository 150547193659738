import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";
import StateManagmentProvider from "./components/common/StateContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StateManagmentProvider>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </StateManagmentProvider>
);
