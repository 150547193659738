import React, { useRef, useState } from "react";
import Slider from "react-slick";
import {
  ChennalIcon,
  CommonLeftArrowIcon,
  CommonRightArrowIcon,
  CommonXmildIcon,
  HeartIcon,
} from "../common/Icon";
import { fanFavoriteSlider } from "../common/Helper";
import { ratingStars } from "../../utils/CommonFunction";
import { useNavigate } from "react-router-dom";
import { useStateProvider } from "../common/StateContext";

const FanFavorite = () => {
  // FOR REDIRECT TO PATH
  const navigate = useNavigate();

  const [heartClicked, setHeartClicked] = useState([]);
  //DATA IMPORT FROM PROVIDER
  const { setCardData } = useStateProvider();
  // Ref for the Slider component
  const slider = useRef(null);
  // Function to handle left arrow click
  const handleLeftArrow = () => {
    slider.current.slickPrev();
  };
  // Function to handle right arrow click
  const handleRightArrow = () => {
    slider.current.slickNext();
  };

  var settings = {
    dots: false,
    infinite: true,
    nextArrow: ".left-arrow",
    prevArrow: ".right-arrow",
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1.99,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 605,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  // Function a heart clicked
  const likeHandler = (value) => {
    if (heartClicked.includes(value)) {
      const updatedHeartClicked = heartClicked.filter((item) => item !== value);
      setHeartClicked(updatedHeartClicked);
    } else {
      setHeartClicked([...heartClicked, value]);
    }
  };
  //USE THIS FUNCTION TO REDIRECT PAGE TO THE PRODUCT-DETAILS PAGE
  const onNavigateHandler = (value) => {
    localStorage.setItem("current-movie", JSON.stringify(value));
    const formattedTitle = value.title.replace(/\s+/g, "-");
    navigate(`/product-details?title=${formattedTitle}`);
    localStorage.setItem("current-movie", JSON.stringify(value));
    setCardData(value);
  };

  return (
    <>
      <section className="relative" id="fanfavorites">
        <div className="common_bg_gradient_color  max-w-[343px] h-[343px] rounded-[343px] blur-[250px] opacity-70 absolute end-0 top-0 w-full hidden md:block"></div>
        <span className=" absolute left-0 bottom-0 ps-6 hidden lg:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        <div className="container max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto  py-12">
          <h2 className="ff_poppins font-semibold text-[30px] md:text-[40px] text-white text-center mb-0">
            Fan <span className="common-text-gradient">favorites</span>
          </h2>

          <div className="relative pt-12 xl:pt-[58px] min-[1440px]:max-w-[880px] min-[1440px]:mx-auto min-[1530px]:max-w-[980px] 3xl:max-w-[1200px]  min-[1920px]:max-w-full min-[1920px]:mx-0">
            <Slider className="pb-8 xl:pb-0" ref={slider} {...settings}>
              {fanFavoriteSlider.map((value, index) => {
                const isLiked = heartClicked.includes(index);
                return (
                  <div key={index} className="relative">
                    <span
                      className={`${
                        isLiked ? "liked" : ""
                      } absolute top-[14px] right-[14px] pt-3 pe-3 cursor-pointer z-20`}
                      onClick={() => likeHandler(index)}>
                      <HeartIcon filled={isLiked} />
                    </span>
                    <div
                      onClick={() => onNavigateHandler(value)}
                      className="group rounded-xl cursor-pointer card_backdrop_filter border border-solid border-[#474643] p-2 mx-2 mb-2">
                      <div
                        className={`flex bg-cover bg-no-repeat rounded-xl bg-center h-[530px] relative p-2`}
                        style={{
                          backgroundImage: `url(${value.image})`,
                        }}>
                        <div className=" flex  flex-col flex-grow justify-end">
                          <h5 className="ff_poppins text-[16px]  font-semibold text-white opacity-90  mb-2">
                            {value.title}
                          </h5>
                          <span className="flex items-center text-2xl text-white">
                            <ChennalIcon />+
                          </span>
                          <div className=" flex  items-center gap-2 mb-1">
                            <p className="ff_poppins text-[14px] font-normal text-white opacity-70 mb-0">
                              Your rating: {value.rating}/5
                            </p>
                            <span className="flex gap-1">
                              {ratingStars(value.rating)}
                            </span>
                          </div>
                          <div className="flex gap-3 pt-3">
                            <button className=" common_bg_gradient_color ff_inter font-semibold text-[16px] text-white border w-full py-[11px] px-4 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex justify-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                              Watchlist
                            </button>

                            <button className="common-border-button ff_inter font-semibold text-[16px] text-white opacity-90 w-full py-[11px] px-4 rounded overflow-hidden border border-solid border-[#f2cd75] bg-black hover:border-transparent after:bg-[#a97424]  z-10 flex justify-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                              Trailer
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div className="justify-center gap-10 xl:gap-0 pt-10 xl:pt-0 hidden xl:flex">
              <button
                aria-label="Slider Arrow"
                onClick={handleLeftArrow}
                className={`common-arrow left-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-1/2 left-[-5%]  `}>
                <CommonLeftArrowIcon />
              </button>
              <button
                aria-label="Slider Arrow"
                onClick={handleRightArrow}
                className={`common-arrow right-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-1/2 right-[-5%]  `}>
                <CommonRightArrowIcon />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FanFavorite;
