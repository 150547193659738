import React, { useRef } from "react";
import {
  ChennalIcon,
  CommonLeftArrowIcon,
  CommonRightArrowIcon,
  CommonXmildIcon,
  ShareIcon,
} from "../common/Icon";
import Slider from "react-slick";
import { ratingStars } from "../../utils/CommonFunction";
import { featuredSlider } from "../common/Helper";
import { useNavigate } from "react-router-dom";
import { useStateProvider } from "../common/StateContext";

const FeaturedToday = () => {
  //FOR REDIRECT TO PATH
  const navigate = useNavigate();
  // Ref for the Slider component
  const slider = useRef(null);
  //IMPORT SERCARDDATA FROM PROVIDER
  const { setCardData } = useStateProvider();
  // Function to handle left arrow click
  const handleLeftArrow = () => {
    slider.current.slickPrev();
  };
  // Function to handle right arrow click
  const handleRightArrow = () => {
    slider.current.slickNext();
  };
  //SLIDER SETTINGS
  var settings = {
    dots: false,
    infinite: true,
    nextArrow: ".left-arrow",
    prevArrow: ".right-arrow",
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 1.017,
          
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 1.01,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          autoplay: true,
        },
      },
    ],
  };
  //FUNCTION FOR REDIRECT TO PRODUCT-DETAILS PAGE
  const onNavigateHandler = (value) => {
    localStorage.setItem("current-movie", JSON.stringify(value));
    const formattedTitle = value.title.replace(/\s+/g, "-");
    navigate(`/product-details?title=${formattedTitle}`);
    setCardData(value);
  };
  return (
    <>
      <section
        className=" relative pb-0 md:pt-[170px] xl:pt-[175px] md:pb-[140px]"
        id="featured">
        <span className=" absolute left-0 top-0 ps-6 hidden lg:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        <div className="container max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto  pt-10 md:pt-20">
          <div className=" flex flex-col md:flex-row items-center pb-10 min-[1440px]:max-w-[1000px] min-[1440px]:mx-auto min-[1530px]:max-w-full min-[1530px]:mx-0 ">
            <div className="w-full  md:w-1/2 lg:w-[40%] min-[1440px]:w-[35%]">
              <h2 className="ff_poppins font-semibold text-[30px] md:text-[40px] text-white mb-0 text-center md:text-start">
                Featured
                <span className="common-text-gradient"> today</span>
              </h2>
              <p className="ff_poppins text-[14px] md:text-base font-normal text-white leading-[160%] opacity-70 pt-3 md:pt-4 text-center md:text-start">
                Amet suscipit risus nulla libero porta purus id rhoncus emper
                faucibus risus cursus dibero ipsum tempus rhoncus dignissi massa
                cursus tortor.
              </p>
              <div className=" justify-center md:justify-start gap-5 pt-10 hidden md:flex">
                <button
                  aria-label="Slider Arrow"
                  onClick={handleLeftArrow}
                  className={`common-arrow left-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent `}>
                  <CommonLeftArrowIcon />
                </button>
                <button
                  aria-label="Slider Arrow"
                  onClick={handleRightArrow}
                  className={`common-arrow right-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent 
                   `}>
                  <CommonRightArrowIcon />
                </button>
              </div>
            </div>
            <div className="w-full md:w-[45%] lg:w-[55%] xl:w-[55%] min-[1530px]:w-[65%]  md:absolute right-0 pt-8 md:pt-0  md:my-11">
              <Slider className="pb-8 md:pb-0" ref={slider} {...settings}>
                {featuredSlider.map((value, index) => {
                  return (
                    <div onClick={() => onNavigateHandler(value)} key={index}>
                      <div className=" cursor-pointer rounded-xl border border-solid border-[#474643] p-2 mx-[10px] mb-2">
                        <div
                          className={`flex bg-cover bg-no-repeat bg-center rounded-xl h-[450px] lg:h-[505px] relative p-2 `}
                          style={{
                            backgroundImage: `url(${value.image})`,
                          }}>
                          <div className=" flex  flex-col flex-grow justify-end">
                            <h5 className="ff_poppins text-[16px]  font-semibold text-white opacity-90  mb-0">
                              {value.title}
                            </h5>
                            <span className="flex items-center text-2xl text-white">
                              <ChennalIcon />+
                            </span>
                            <p className="ff_poppins text-[14px] font-normal text-white opacity-70 mb-3">
                              {value.description}
                            </p>
                            <span className="flex gap-1">
                              {ratingStars(value.rating)}
                            </span>
                          </div>
                        </div>
                        <div className="flex  w-full gap-2 lg::gap-4 justify-between mt-2 ">
                          <span className="flex w-full">
                            <button className=" common_bg_gradient_color ff_inter font-semibold text-[16px] text-white border w-full py-[11px] px-1 xl:px-3 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex justify-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                              Add a Watchlist
                            </button>
                          </span>
                          <span className="flex w-full">
                            <button className=" common_bg_gradient_color ff_inter font-semibold text-[16px] text-white border w-full py-[11px] px-1 xl:px-3 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex justify-center items-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                              <ShareIcon />
                              Share
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedToday;
