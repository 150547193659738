import React, { useRef, useState } from "react";
import {
  CommonLeftArrowIcon,
  CommonRightArrowIcon,
  DownloadIcon,
} from "../common/Icon";
import top_yellow_bar from "../../assets/images/mostratedmovies/slider_yellow-bars.png";
import bottom_yellow_bar from "../../assets/images/mostratedmovies/slider_yellow-bars_right_bottom.png";
import top_Dot_line from "../../assets/images/mostratedmovies/doted_line.svg";
import bottom_dot_line from "../../assets/images/mostratedmovies/doted_line.svg";
import Slider from "react-slick";
import mostratedreelimg from "../../assets/images/mostratedmovies/XMLID_reel.svg";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { useStateProvider } from "../common/StateContext";
import { formatNumber, ratingStars } from "../../utils/CommonFunction";

const MostRatedMovie = ({ mapData, btn, datadisplay }) => {
  //FOR GETTING PATH
  const location = useLocation();
  // IMPORT FROM PROVIDER'S STATE
  const { setCardData } = useStateProvider();
  //FOR REDIRECT TO ANY OTHER PAGE
  const navigate = useNavigate();
  // STATE TO SET ACTIVE ARROW OF SLIDER
  const [isLoading, setIsLoading] = useState(false);
  // State to manage selected indexes for Read More functionality
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  // REF FOR GET SLIDER ARROW
  const mostRatedSlider_slider = useRef(null);
  // FUNTION FOR HANDLE LEFT ARROW
  const handleLeftArrow = () => {
    mostRatedSlider_slider.current.slickPrev();
  };
  // FUNTION FOR HANDLE RIGHT ARROW
  const handleRightArrow = () => {
    mostRatedSlider_slider.current.slickNext();
  };
  // SLIDER PROPERTIES AND RESPONSIVE
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    // autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          dots: true,
        },
      },
    ],
  };
  // Function to handle Read More button click
  const handleReadMoreClick = (index) => {
    if (selectedIndexes.includes(index)) {
      const data = selectedIndexes.filter((obj) => obj !== index);
      setSelectedIndexes(data);
    } else {
      setSelectedIndexes((prev) => [...prev, index]);
    }
  };
  const onNavigateHandler = (value) => {
    localStorage.setItem("current-movie", JSON.stringify(value));
    const formattedTitle = value.title.replace(/\s+/g, "-");
    navigate(`/product-details?title=${formattedTitle}`);
    localStorage.setItem("current-movie", JSON.stringify(value));
    setCardData(value);
  };

  return (
    <div
      className="bg-most_rated_bg most_rated_bg bg-no-repeat relative xl:pt-[30px]"
      id="mostrated">
      <div
        className={`common_bg_gradient_color  w-[326px] h-[326px] rounded-[326px] blur-[250px] absolute left-[-50px] bottom-[-50px] z-10 ${
          location.pathname === "/category" ? "hidden" : "hidden md:block" //ACTIVE ROUTES
        }`}></div>
      <img
        className="absolute right-0 sm:top-[94px] top-[120px] lg:max-w-[130px] xl:max-w-[202px] md:max-w-[150px] max-w-[100px] hidden lg:block"
        src={mostratedreelimg}
        loading="lazy"
        alt="reel"
      />
      <div className="container md:max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto lg:pb-[110px] sm:pb-[70px] pb-[50px] xl:px-0 pt-12 xl:pt-[71px]">
        <h3
          className={`text-center ff_poppins font-semibold text-[30px] md:text-[40px] text-white ${
            location.pathname === "/category" ? "hidden" : "" //ACTIVE ROUTES
          }`}>
          Most rated <span className="common-text-gradient">movies</span>
        </h3>
        {/* MOST RATED SLIDER */}
        <div className="relative z-50 min-[1440px]:max-w-[880px] min-[1530px]:max-w-[980px] min-[1440px]:mx-auto  3xl:max-w-[1200px] min-[1920px]:max-w-full min-[1920px]:mx-0">
          <Slider
            ref={mostRatedSlider_slider}
            {...settings}
            className="relative pt-[50px] pb-8 xl:pb-0">
            {/* MOST RATED SLIDER CARDS BY MAP  */}
            {mapData.map((data, index) => {
              const textLength = data.description.length;
              const value = selectedIndexes.includes(index)
                ? data.description
                : data.description.substring(0, 150);
              return (
                <div key={index} className="px-2 xl:pb-0">
                  <div className="flex bg-[#FFFFFF0A] backdrop-blur-[136px] lg:gap-[50px] gap-6 items-center sm:ps-10 ps-3 lg:pe-[40px] sm:pe-8 pe-3 py-[30px] relative rounded-xl md:flex-row flex-col">
                    <img
                      className="absolute top-0 md:max-w-[282px] max-w-[200px] md:left-[32%]"
                      src={top_yellow_bar}
                      alt="deco_bar"
                      loading="lazy"
                    />
                    <img
                      className="absolute md:top-[25px] top-[15px] md:max-w-[282px] max-w-[200px] left-[37%]"
                      src={top_Dot_line}
                      alt="dot_bar"
                      loading="lazy"
                    />
                    <img
                      className="absolute bottom-0 md:max-w-[282px] max-w-[200px] right-0"
                      src={bottom_yellow_bar}
                      alt="deco_bar"
                      loading="lazy"
                    />
                    <img
                      className="absolute md:bottom-[25px] bottom-[15px] md:max-w-[282px] max-w-[200px] right-[5%]"
                      src={bottom_dot_line}
                      alt="dot_bar"
                      loading="lazy"
                    />
                    <div className="min-w-[270px] rounded-lg relative w-[270px] h-[381px]  overflow-hidden border-2 border-solid border-[#FFFFFF4D] rated_movies_shadow max-w-[400px]">
                      {!isLoading ? (
                        <div className="skeleton-loader"></div>
                      ) : (
                        ""
                      )}
                      <img
                        className={isLoading ? "w-full h-full" : "w-0 h-0"}
                        src={data.image}
                        onLoad={() => setIsLoading(true)}
                        alt="movie-posture"
                        loading="lazy"
                      />
                    </div>
                    <div className="relative  watch_bg_shadow after:content-[''] after:absolute after:top-0 sm:after:left-1/3 sm:after:-translate-x-1/2 sm:sm:after:w-[391px] sm:after:w-[143px] sm:sm:after:h-[150px] sm:after:h-[143px] sm:after:rounded-full sm:after:z-0">
                      <div className="flex justify-between items-center">
                        <h4 className="ff_poppins font-semibold md:text-[24px] text-[15px] text-white opacity-90">
                          {data.title}
                        </h4>

                        <button
                          onClick={() => onNavigateHandler(data)}
                          className=" w-[150px] md:w-[170px] common_bg_gradient_color ff_inter font-semibold text-[14px] md:text-[16px] text-white border py-2 md:py-[10px] sm:px-4 px-2 gap-1 max-w-[170px] custom-sm:mt-0 mt-2 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex items-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0 justify-center">
                          {btn === "Join Now" ? (
                            ""
                          ) : (
                            <span>
                              {" "}
                              <DownloadIcon />
                            </span>
                          )}

                          {btn}
                        </button>
                      </div>
                      <div className={datadisplay}>
                        <div className="flex justify-between xs:justify-normal  overflow-x-auto whitespace-nowrap max-w-[260px] 2xs:max-w-none 2xs:overflow-x-hidden gap-5 2xs:gap-[5px] xs:gap-[20px] lg:gap-[15px] pt-3 pb-5">
                          <h5 className="ff_poppins font-normal text-[12px] sm:text-[14px] text-white opacity-70">
                            {formatNumber(189000)} Reviews
                          </h5>
                          <h5 className="ff_poppins font-normal text-[12px] sm:text-[14px] text-white opacity-70">
                            {formatNumber(29000000)}+ Download
                          </h5>
                          <h5 className="ff_poppins font-normal text-[12px] sm:text-[14px] text-white opacity-70">
                            {data.rating}+ Rated out of 5
                          </h5>
                        </div>
                      </div>
                      <span className="flex gap-1">
                        {ratingStars(data.rating)}
                      </span>
                      <h5 className="ff_poppins font-normal lg:text-[16px] text-[14px] text-white opacity-80 pt-5 lg:pb-[30px] pb-[15px] md:block hidden ">
                        {data.description}
                      </h5>
                      {/* ADD READ MORE BUTTON IN TEXT */}
                      <h5 className="ff_poppins font-normal lg:text-[16px] text-[14px] text-white opacity-80 pt-5 lg:pb-[30px] pb-[15px] md:hidden block">
                        <span>{value} </span>
                        {selectedIndexes === index ? (
                          <span>{data.hiddendata}</span>
                        ) : (
                          data.threedots
                        )}{" "}
                        {textLength < 200 ? (
                          ""
                        ) : (
                          <button
                            className="whitespace-nowrap cursor-pointer relative z-10 ff_poppins font-normal lg:text-[16px] text-[14px] text-[#D4A954]"
                            onClick={() => handleReadMoreClick(index)}>
                            {selectedIndexes.includes(index)
                              ? "Read less"
                              : "...Read more"}
                          </button>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {/* SLIDER ARROWS  */}
          <div className=" justify-center gap-10 xl:gap-0 pt-10 xl:pt-0 hidden xl:flex">
            <button
              aria-label="Slider Arrow"
              onClick={handleLeftArrow}
              className={`common-arrow left-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-1/2 left-[-5%] `}>
              <CommonLeftArrowIcon />
            </button>
            <button
              aria-label="Slider Arrow"
              onClick={handleRightArrow}
              className={`common-arrow right-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-1/2 right-[-5%] `}>
              <CommonRightArrowIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostRatedMovie;
