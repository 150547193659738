import React, { useRef } from "react";
import Slider from "react-slick";
import {
  ChennalIcon,
  CommonLeftArrowIcon,
  CommonRightArrowIcon,
  ShareIcon,
} from "../common/Icon";
import { useNavigate } from "react-router-dom";
import { useStateProvider } from "../common/StateContext";
import { ratingStars } from "../../utils/CommonFunction";

const CategorySlider = ({ categorySliderData }) => {
  // FUNTION FOR SLIDER LEFT ARROW
  const slider = useRef(null);
  // NAVIGATION FOR PATH
  const navigate = useNavigate();
  // DATA FOR PASS VALUE IN PRODUCT-DETAIL PAGE
  const { setCardData } = useStateProvider();
  // REF FOR THE SLIDER
  const handleLeftArrow = () => {
    slider.current.slickPrev();
  };
  // FUNCTION TO HENDLE RIGHT ARROW CLICK
  const handleRightArrow = () => {
    slider.current.slickNext();
  };
  // SLIDER PROPERTIES AND RESPONSIVE
  var settings = {
    dots: false,
    infinite: true,
    nextArrow: ".left-arrow",
    prevArrow: ".right-arrow",
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2.018,
          slidesToScroll: 3,
          dots: false,
        },
      },

      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  const onNavigateHandler = (value) => {
    localStorage.setItem("current-movie", JSON.stringify(value));
    const formattedTitle = value.title.replace(/\s+/g, "-");
    navigate(`/product-details?title=${formattedTitle}`);
    setCardData(value);
  };
  return (
    <>
      <section className="relative">
        <div className="container max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto z-10 relative">
          <div className="relative pt-12 xl:pt-[60px] min-[1440px]:max-w-[880px] min-[1440px]:mx-auto min-[1530px]:max-w-[980px] 3xl:max-w-[1200px]  min-[1920px]:max-w-full min-[1920px]:mx-0">
            {/* SLIDER ARROWS */}
            <div className="justify-center gap-10 xl:gap-0 pt-10 xl:pt-0 hidden xl:flex">
              <button
                aria-label="Card Slide"
                onClick={handleLeftArrow}
                className={`common-arrow left-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-[47%] left-[-5%]
                `}>
                <CommonLeftArrowIcon />
              </button>
              <button
                aria-label="Card Slide"
                onClick={handleRightArrow}
                className={`common-arrow right-arrow  w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-[47%] right-[-5%]
                `}>
                <CommonRightArrowIcon />
              </button>
            </div>
            {/* MOST POPULAR SLIDER  */}
            <Slider className="pb-8 xl:pb-0" ref={slider} {...settings}>
              {categorySliderData.map((value, index) => {
                return (
                  <div onClick={() => onNavigateHandler(value)} key={index}>
                    <div className="group rounded-xl card_backdrop_filter border cursor-pointer border-solid border-[#474643] p-2 bg-[#1A1917] mx-2 mb-2">
                      <div className={`flex rounded-xl w-full `}>
                        <div>
                          <div className="overflow-hidden rounded-md">
                            <img
                              height={303}
                              width={347}
                              className="group-hover:scale-110 duration-300 rounded-[6px] w-full h-full"
                              src={value.image}
                              alt="img"
                              loading="lazy"
                            />
                          </div>
                          <div className=" mt-4  pb-[11px]">
                            <div className="ps-[11px]">
                              <h5 className="ff_poppins text-[14px] xl:text-[20px]  font-semibold text-white opacity-90 mb-0">
                                {value.title}
                              </h5>
                              <span className="flex items-center text-2xl text-white">
                                <ChennalIcon />+
                              </span>
                              <p className="ff_poppins text-[16px] font-normal text-[#BBBAB9] mt-1 mb-2">
                                Your rating: {value.rating}/5
                              </p>
                              <span className="flex gap-1">
                                {ratingStars(value.rating)}
                              </span>
                            </div>
                            <div className="flex  w-full gap-2 lg::gap-4 justify-between mt-4">
                              <span className="flex w-full">
                                <button className=" common_bg_gradient_color ff_inter font-semibold text-[16px] text-white border w-full py-[11px] px-1 xl:px-4 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex justify-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                                  Add a Watchlist
                                </button>
                              </span>
                              <span className="flex w-full">
                                <button className=" common_bg_gradient_color ff_inter font-semibold text-[16px] text-white border w-full py-[11px] px-1 xl:px-4 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex justify-center items-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                                  <ShareIcon />
                                  Share
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategorySlider;
