import { useEffect, useState } from "react";
import { BackToTopIcon } from "./Icon";

const BackToTop = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  // SCROLL TO TOP FUNCTION WHEN CLICK ON THIS PAGE SCROLL TOP
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  // TO FIND SCROLL Y POSITION
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  // THIS USEFFECT GIVE US POSITION OF SCROLL IN EVERY PIXELS WE SCROLL
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* // IF PAGE SCROLL VALUE GRATER THAN 500 THEN SHOW BACK TO TOP BUTTON */}
      {scrollPosition > 300 && (
        <div
          className="back-to-top fixed bottom-[4%] right-[2.2%] flex justify-center items-center common_bg_gradient_color z-50 rounded-[100%] cursor-pointer animate-[wiggle_2s_ease-in-out_infinite] hover_shadow duration-300 sm:w-12 w-8 sm:h-12 h-8"
          onClick={() => scrollToTop()}
        >
          <BackToTopIcon />
        </div>
      )}
    </>
  );
};

export default BackToTop;
