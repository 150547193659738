import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/common/Footer";
import RmsRouter from "./components/common/RmsRouter";
import Header from "./components/common/Header";
import Sidebar from "./components/common/Sidebar";
import { useStateProvider } from "./components/common/StateContext";
import { useEffect } from "react";
import { RiMenuUnfoldLine } from "react-icons/ri";
import MyFriendsSidebar from "./components/common/MyFriendsSidebar";

function App() {
  const { showFriendData, setShowFriendData } = useStateProvider();
  //SHOWDATA STATES GETTING FROM PROVIDER
  const { showData, setShowData } = useStateProvider(); //OPEN SIDEBAR
  // OVERFLOW HIDDEN WHEN SIDEBAR OPEN
  useEffect(() => {
    if (showData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showData]);

  // OVERLAY BEHIND SIDEBAR
  const overLayHidden = () => {
    setShowData(!showData);
    setShowFriendData(!showFriendData);
  };
  return (
    <div className="overflow-x-hidden relative z-[11]">
      <div className=" min-[1440px]:fixed top-0 left-0 z-[999] w-full  min-[1440px]:before:z-[999999] before:content-[''] before:absolute before:top-0 before:left-[-50%] md:before:left-[-10%] account_data_shadow before:w-[370px] before:h-[370px] before:-translate-x-1/2 before:-translate-y-1/2 after:content-[''] after:absolute after:bottom-0 after:left-0 watch_bg_shadow  after:w-[100px] sm:after:w-[370px] sm:after:h-[370px] after:h-[100px] after:-translate-x-1/2 after:z-[0]">
        <Header />
      </div>

      <div className=" min-[1440px]:flex ">
        <div
          className={`min-w-[173px] min-[576px]:min-w-[190px]  py-5 bg-[#FFFFFF14] backdrop-blur-[136px] h-screen min-[1440px]:h-[calc(100vh-76px)]  min-[1440px]:start-0 top-0   min-[1440px]:top-[76px] fixed z-[9999999] duration-300 overflow-y-scroll ${
            showData ? "left-0" : "-left-full"
          }`}>
          <Sidebar />
        </div>
        <button
          aria-label="Openlink"
          className="text-[30px] font-semibold text-white ms-3 mt-3 hidden"
          onClick={() => {
            setShowData(!showData);
            setShowFriendData(!showFriendData);
          }}>
          <RiMenuUnfoldLine />
        </button>
        {/* ==== MyFriendsSidebar ======= */}
        <div
          className={` w-[300px]  pt-5 top-0  min-[1440px]:top-[76px] fixed duration-300 z-[100]  ${
            showFriendData ? "left-0" : "-left-[500px] min-[1440px]:start-0"
          }`}>
          <MyFriendsSidebar />
        </div>

        <div className="w-full min-[1440px]:pl-[431px] ">
          <RmsRouter />
        </div>
      </div>

      <Footer />
      {/* Sidebar Overlay */}
      <div
        onClick={overLayHidden}
        className={` fixed inset-0 min-h-full z-[99] backdrop-blur-[6px] cursor-pointer ${
          showData ? "w-[100%] " : "hidden"
        }`}></div>
    </div>
  );
}

export default App;
