import React, { useEffect } from "react";
import accountreelimg from "../../assets/images/watch_videos/watch_movie_reel.png";
import Faq from "../common/Faq";
import MyFriendsSidebar from "../common/MyFriendsSidebar";
import { useStateProvider } from "../common/StateContext";
import { FriendMenuIcon } from "../common/Icon";

const MyFriends = () => {
  const { showFriendData, setShowFriendData } = useStateProvider();
  // OVERFLOW HIDDEN WHEN SIDEBAR OPEN
  useEffect(() => {
    if (showFriendData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showFriendData]);
  return (
    <>
      <div className="bg-my_account_bg bg-cover relative min-h-screen ">
        <div className="bottom_blur_hero w-full h-[50px] absolute bottom-0 left-0"></div>

        <img
          className="absolute right-0 top-[230px] max-w-[170px] md:block hidden"
          src={accountreelimg}
          alt="reel"
        />

        <div
          className={` w-[300px]  pt-5  top-0 absolute duration-300 z-[100] ${
            showFriendData ? "left-0" : "-left-full lg:left-0"
          }`}>
          <MyFriendsSidebar />
        </div>
        <button
          aria-label="Openlink"
          className="text-[30px] font-semibold text-white ms-3 mt-3 "
          onClick={() => setShowFriendData(!showFriendData)}>
          <FriendMenuIcon />
        </button>
        {/* Sidebar Overlay */}
        <div
          onClick={() => setShowFriendData(!showFriendData)}
          className={` fixed inset-0 min-h-full z-[99] backdrop-blur-[6px] cursor-pointer ${
            showFriendData ? "w-[100%] " : "hidden"
          }`}></div>
        <div className="container md:max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto flex  pb-16 justify-start sm:px-4 lg:px-0 px-0 min-h-[calc(100vh-76px)] ">
          <div className="tab_data w-full">
            <div className="flex items-center justify-center h-full ">
              <p className="text-center text-white text-[24px] md:text-[40px] ff_poppins font-semibold pt-10">
                Coming Soon
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:pt-[60px] pt-5">
        <Faq />
      </div>
    </>
  );
};

export default MyFriends;
