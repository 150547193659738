import React from "react";
import { CommonXmildIcon, SearchIcon } from "../common/Icon";
import category_hero_img from "../../assets/images/webp/category_hero_img.webp";
import category_hero_reel_img from "../../assets/images/png/category_hero_reel_img.png";

const HeroCategory = () => {
  return (
    <>
      <section className=" md:h-[calc(100vh-81px)] min-[1440px]:pl-4 bg-homepage-hero-bg bg-cover bg-no-repeat bg-center relative z-10 flex ">
        <div className="bottom_blur_hero w-full h-[50px] absolute bottom-0 left-0 z-50"></div>
        {/* SHOT-TAKE-ACTION-IMAGE-POSITION */}
        <span className=" absolute left-0 top-0 min-[1440px]:top-[74px] ps-5 md:pt-5 pt-10 hidden lg:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        {/* REEL ICON */}
        <img
          className=" w-[240px] h-[381px] object-cover absolute end-0 bottom-[110px] 2xl:bottom-[5%] xl:bottom-[20%] -z-0  hidden xl:block"
          src={category_hero_reel_img}
          alt="popular_reel_position_img"
        />
        <div className="container max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto flex-grow flex justify-center items-center">
          <div className="flex flex-col-reverse md:flex-row justify-between items-center ">
            <div className="w-full sm:w-[75%] md:w-1/2 lg:w-[39%] pt-10 md:pt-0 pb-12 md:pb-0">
              <h2 className="ff_poppins font-bold text-[35px] lg:text-[40px] xl:text-[55px] text-white leading-[142%]">
                TV <span className="common-text-gradient">Shows</span>
              </h2>
              <p className=" ff_poppins text-[16px] font-normal opacity-70 text-white pt-3 mb-0 leading-[160%]">
                Sit volutpat purus semper metus laoreet amet justo suscipit
                posuere vestibulum eget erat aliquet quam donec donec
                suspendisse.
              </p>
              {/* SEARCH BAR ON MIN MD*/}
              <form className="border hidden  border-solid border-[#82817F] rounded-[127px] md:flex items-center justify-between w-full md:w-[80%] mt-10 ps-3">
                <input
                  className=" placeholder:text-white placeholder:text-[14px] placeholder:font-normal  ff_poppins text-[14px] font-normal text-white bg-transparent p-2 px-3 lg:p-3 w-11/12 outline-none"
                  type="text"
                  placeholder="Search"
                  required
                />
                <button
                  type="submit"
                  aria-label="Search"
                  className="w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-[50%] p-2 flex justify-center items-center cursor-pointer common_bg_gradient_color">
                  <SearchIcon />
                </button>
              </form>
            </div>
            <div className=" w-full sm:w-[70%] md:w-[45%] ">
              {/* SEARCH BAR ON MAX MD*/}
              <form className="border md:hidden  border-solid border-[#82817F] rounded-[127px] flex items-center justify-between w-full md:w-[80%] my-10 ps-3">
                <input
                  className=" placeholder:text-white placeholder:text-[14px] placeholder:font-normal  ff_poppins text-[14px] font-normal text-white bg-transparent p-2 px-3 lg:p-3 w-11/12 outline-none"
                  type="text"
                  placeholder="Search"
                  required
                />
                <button
                  type="submit"
                  aria-label="Search"
                  className="w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-[50%] p-2 flex justify-center items-center cursor-pointer common_bg_gradient_color">
                  <SearchIcon />
                </button>
              </form>
              <div className="border-2 border-solid border-[#FFFFFF4D] rounded-lg z-50 relative overflow-hidden">
                <img
                  height={523}
                  width={523}
                  className="w-full h-full hover:scale-110 duration-300 z-20"
                  src={category_hero_img}
                  alt="hero-img"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroCategory;
