import React, { useEffect } from "react";
import Faq from "../common/Faq";
import reel_img from "../../assets/images/svg/item-reel-image.svg";
import ProductDetailsHero from "./ProductDetailsHero";

const ProductDetails = () => {
  //ONSCROLL FUNCTION
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });
  return (
    <>
      <div>
        <div className="relative">
          {/* REEL POSITION IMAGE */}
          <img
            className="absolute start-0 top-full max-w-[10%] min-w-[100px] z-0 hidden lg:block"
            src={reel_img}
            alt="reel"
          />
          {/* BUTTOM BLUR  */}
          <div className="bottom_blur_hero h-12 md:h-[80px] absolute bottom-0 left-0 w-full z-10 hidden md:block"></div>
          {/* ITEM PAGE SHADOWS  */}
          <div className="common_bg_gradient_color  max-w-[343px] h-[343px] rounded-[343px] blur-[250px] opacity-80 absolute start-0 top-0 w-full hidden md:block"></div>
          <div className="common_bg_gradient_color  max-w-[343px] h-[343px] rounded-[343px] blur-[250px] opacity-80 absolute start-0 top-1/2 w-full hidden md:block"></div>
          <div className="common_bg_gradient_color  max-w-[343px] h-[343px] rounded-[343px] blur-[250px] opacity-80 absolute end-0 bottom-[10%] w-full hidden md:block"></div>
          <ProductDetailsHero />
        </div>
        <Faq />
      </div>
    </>
  );
};

export default ProductDetails;
