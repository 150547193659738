import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/png/nav-logo.png";
import { Link, useLocation } from "react-router-dom";

import {
  CrossIcon,
  DropDownIcon,
  MenuIconBlack,
  NavNotificationIcon,
} from "./Icon";
import { dropdownData } from "./Helper";
import { RiMenuUnfoldLine } from "react-icons/ri";
import { useStateProvider } from "./StateContext";

const Header = () => {
  //FOR GETTING PATH
  const loaction = useLocation();
  // Dropdown State Management
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedOption, setSelectedOption] = useState();

  const [toggle, setToggle] = useState(false);

  // Close the mobile navigation menu when a link is clicked
  let links = document.querySelectorAll(".nav_links");
  links.forEach((item) => {
    item.addEventListener("click", function () {
      setToggle(false);
    });
  });

  // Disable body scrolling when mobile navigation menu is open
  if (toggle) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  //SHOWDATA STATES GETTING FROM PROVIDER
  const { showData, setShowData, showFriendData, setShowFriendData } =
    useStateProvider(); //OPEN SIDEBAR

  // OVERFLOW HIDDEN WHEN SIDEBAR OPEN
  useEffect(() => {
    if (showData) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showData]);

  // OVERLAY BEHIND SIDEBAR
  const overLayHidden = () => {
    setShowData(!showData);
  };

  return (
    <>
      <nav className="bg-[#000] w-full">
        <div className="container max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto flex justify-between items-center py-2">
          <div className="flex items-center z-20">
            <button
              aria-label="Openlink"
              className={`text-[30px] font-semibold text-white me-3 min-[1440px]:hidden  flex   ${
                setShowData ? " " : " "
              }`}
              onClick={() => {
                setShowData(!showData);
                setShowFriendData(!showFriendData);
              }}>
              <RiMenuUnfoldLine />
            </button>

            <Link
              className="inline-block cursor-pointer"
              aria-label="logo"
              to={"/"}>
              <img className=" w-[103px]" src={Logo} alt="logo" />
            </Link>
          </div>
          {/* Desktop navigation menu */}
          <div className="hidden sm:block">
            {/* Movie dropdown menu */}
            <div className="flex  gap-8 items-center ">
              {showOverlay ? (
                <div
                  onClick={() => {
                    setDropdownOpen(null);
                    setShowOverlay(false);
                  }}
                  className="fixed top-0 left-0 w-screen h-screen z-[999]"></div>
              ) : (
                ""
              )}
              {dropdownData.map((data, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setDropdownOpen((prevState) =>
                      prevState === index ? null : index
                    );
                  }}
                  className="flex cursor-pointer items-center justify-between relative ">
                  <div
                    className="flex cursor-pointer items-center justify-between"
                    onClick={() => setShowOverlay(true)}>
                    <p className="pe-3 ff_poppins text-[14px] font-normal text-white opacity-70 hover:opacity-100 duration-300">
                      {data.title}
                    </p>
                    <span
                      className={`duration-200    ${
                        dropdownOpen === index ? "rotate-180" : ""
                      }`}>
                      <span className="block">
                        <DropDownIcon />
                      </span>
                    </span>
                  </div>
                  {dropdownOpen === index && (
                    <ul className="cursor-pointer overflow-hidden absolute z-[1000] top-[30px]  right-0 w-[150px] p-0 m-0  list-none bg-[rgba(255,_255,_255,_0.100)] backdrop-blur-[136.5px] shadow-[0px_5.86667px_17.6px_0px_rgba(0,_0,_0,_0.10)] rounded-md">
                      <div
                        onClick={() => setShowOverlay(false)}
                        className="overflow-y-auto h-[100%]">
                        {data.subTitle.map((obj, subIndex) => (
                          <Link
                            to={obj.link}
                            className="ff_poppins text-[14px] w-full inline-block font-normal text-white opacity-70 hover:opacity-100 border-l-2 border-solid border-transparent hover:border-[rgba(255,_255,_255,_0.25)]  dropdown_blur p-3 my-1"
                            key={subIndex}
                            onClick={() => {
                              setSelectedOption(false);
                              setToggle(false);
                            }}>
                            {obj.title}
                          </Link>
                        ))}
                      </div>
                    </ul>
                  )}
                </div>
              ))}
              <Link
                to={"/account-detail"}
                className={`ff_poppins text-[14px] font-normal text-white  hover:opacity-100 duration-300 ${
                  loaction.pathname === "/account-detail"
                    ? "opacity-100"
                    : "opacity-70"
                }`}>
                MY ACCOUNT
              </Link>
              {/* Notification icon */}
              <li className="cursor-pointer">
                <span>
                  <NavNotificationIcon />
                </span>
              </li>
            </div>
          </div>

          {/* Mobile navigation menu toggle button */}
          <div className="sm:hidden flex flex-row-reverse items-center gap-4">
            <button
              aria-label="show-navbar"
              onClick={() => {
                setToggle(true);
              }}>
              <MenuIconBlack />
            </button>
            <span className=" cursor-pointer">
              <NavNotificationIcon />
            </span>
          </div>
        </div>
      </nav>
      {/* Mobile navigation menu */}
      <div
        className={`transition-all transition-linear duration-500 fixed  left-0 w-full z-[1000] h-screen bg-black  flex justify-center items-center flex-col sm:hidden ${
          toggle ? "top-0" : "top-[-300%]"
        }`}>
        {/* Close mobile navigation menu button */}
        <div className="absolute top-0 end-0 p-6">
          <button
            aria-label="Close navbar"
            onClick={() => {
              setToggle(false);
            }}>
            <CrossIcon />
          </button>
        </div>
        <div className="flex  flex-col gap-8 items-center">
          {showOverlay && (
            <div
              onClick={() => {
                setDropdownOpen(null);
                setShowOverlay(false);
              }}
              className="fixed top-0 left-0 w-screen h-screen z-[999]"></div>
          )}
          {dropdownData.map((data, index) => (
            <div
              key={index}
              onClick={() => {
                setDropdownOpen((prevState) =>
                  prevState === index ? null : index
                );
              }}
              className="flex cursor-pointer items-center justify-between relative">
              <div
                className="flex items-center justify-between"
                onClick={() => setShowOverlay(true)}>
                <p className="pe-1 ff_poppins text-[14px] font-normal text-white opacity-70 hover:opacity-100 duration-300">
                  {data.title}
                </p>
                <span
                  className={`duration-200    ${
                    dropdownOpen === index ? "rotate-[270deg]" : ""
                  }`}>
                  <span className=" block">
                    <DropDownIcon />
                  </span>
                </span>
              </div>
              {dropdownOpen === index && (
                <ul className="cursor-pointer  overflow-hidden absolute z-[1000] -top-5  left-[105%] w-[120px] p-0 m-0  list-none bg-[rgba(255,_255,_255,_0.08)] backdrop-blur-[136.5px] shadow-[0px_5.86667px_17.6px_0px_rgba(0,_0,_0,_0.10)] rounded-md">
                  <div
                    onClick={() => setShowOverlay(false)}
                    className="overflow-y-auto h-[100%]">
                    {data.subTitle.map((obj, subIndex) => (
                      <Link
                        to={obj.link}
                        className="ff_poppins text-[14px] w-full inline-block font-normal text-white opacity-70 hover:opacity-100 border-l-2 border-solid border-transparent hover:border-[rgba(255,_255,_255,_0.25)]  dropdown_blur p-2 my-1"
                        key={subIndex}
                        onClick={() => {
                          setSelectedOption(false);
                          setToggle(false);
                        }}>
                        {obj.title}
                      </Link>
                    ))}
                  </div>
                </ul>
              )}
            </div>
          ))}
          <Link
            onClick={() => {
              setToggle(false);
            }}
            to={"/account-detail"}
            className={`ff_poppins text-[14px] font-normal text-white  hover:opacity-100 duration-300 ${
              loaction.pathname === "/account-detail"
                ? "opacity-100"
                : "opacity-70"
            }`}>
            MY ACCOUNT
          </Link>
          {/* Notification icon */}
          <li className="cursor-pointer">
            <span>
              <NavNotificationIcon />
            </span>
          </li>
        </div>
      </div>
      {/* Sidebar Overlay */}
      <div
        onClick={overLayHidden}
        className={` fixed inset-0 min-h-full z-[99] backdrop-blur-[6px] cursor-pointer ${
          showData ? "w-[100%] " : "hidden"
        }`}></div>
    </>
  );
};

export default Header;
