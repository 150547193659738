import React from "react";
import { LikeVideoIcon, MostRatedIcon, PlaylistIcon } from "../common/Icon";
import action_bg_deco from "../../assets/images/myaccount/XMLID_2_.webp";
import accountreelimg from "../../assets/images/watch_videos/watch_movie_reel.png";

import Faq from "../common/Faq";
import AccountSlider from "./AccountSlider";
import {
  likeVideoLibrary,
  mostRankedLibrary,
  playlistLibrary,
} from "../common/Helper";
const Library = () => {
  return (
    <>
      <div className="bg-my_account_bg bg-cover relative min-[1440px]:pt-[100px]">
        <div className="bottom_blur_hero w-full h-[50px] absolute bottom-0 left-0"></div>

        <img
          className="absolute left-4 min-[1440px]:hidden top-[117px] max-w-[70px] z-0 xl:block hidden"
          src={action_bg_deco}
          alt="hero-deco"
        />
        <img
          className="absolute right-0 top-[230px] max-w-[170px] md:block hidden"
          src={accountreelimg}
          alt="reel"
        />
        <div className="container max-w-full  xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto  sm:pb-[144px] pb-16 sm:px-4 xl:px-0 px-0 relative min-h-[50vh]">
          <div className=" sm:pt-[26px] pt-12 tab_data  w-full">
            <div>
              {/* MOST RANKED LIBRARY SLIDER   */}
              <AccountSlider
                icon={<MostRatedIcon />}
                heading={"Most ranked/Suggested videos"}
                sliderArrowHidden={"hidden"}
                accountSliderData={mostRankedLibrary}
                sliderParant="pb-6"
              />
              {/* PLAYLIST LIBRARY SLIDER   */}

              <AccountSlider
                icon={<PlaylistIcon />}
                heading={"Playlists"}
                accountSliderData={playlistLibrary}
                mostRankedSliderArrow={"hidden"}
                sliderParant="pb-6"
              />
              {/* LIKED LIBRARY SLIDER   */}

              <AccountSlider
                icon={<LikeVideoIcon />}
                heading={"Liked Videos"}
                accountSliderData={likeVideoLibrary}
                mostRankedSliderArrow={"hidden"}
                sliderParant="pb-0"
              />
            </div>
          </div>
        </div>

        <div className="sm:pt-[60px] pt-5">
          <Faq />
        </div>
      </div>
    </>
  );
};
export default Library;
