import React, { useEffect } from "react";
import { CommonXmildIcon, SearchIcon } from "../common/Icon";
import { homeHeroLeftSlider, homeHeroRightSlider } from "../common/Helper";
import Typed from "typed.js";

const HomeHero = () => {
  // useEffect hook to handle side effects, runs once after the initial render
  useEffect(() => {
    // Typed.js initialization for text animation
    const typing = new Typed(".text", {
      strings: [
        "ustomer <span style='background: var(--Liner, linear-gradient(135deg, #F2CD75 0%, #A97424 100%)); background-clip: text; -webkit-background-clip: text; -webkit-text-fill-color: transparent;'>Review</span> sites movies",
      ],
      backDelay: 2000,
      typeSpeed: 80,
      backSpeed: 50,
      loop: true,
      showCursor: false,
    });
    // Cleanup function to destroy Typed instance when the component unmounts
    return () => {
      typing.destroy();
    };
  }, []); // Empty dependency array ensures useEffect runs only once
  return (
    <>
      {/* Hero section with background image */}
      <section className="overflow-hidden min-h-screen min-[1440px]:pl-4  grow relative w-full bg-homepage-hero-bg bg-cover bg-no-repeat bg-center py-10 md:py-0 ">
        {/* Bottom blur effect */}
        <div className="bottom_blur_hero h-12 md:h-[80px] absolute bottom-0 xl:bottom-[-30px] left-0 w-full z-10 hidden md:block"></div>
        {/* Movie icon at the top-left corner */}
        <span className=" absolute left-0 top-0 min-[1440px]:hidden ps-5 md:pt-5 pt-10 hidden lg:block movieIcon_animation">
          <CommonXmildIcon />
        </span>
        <div className="container max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto pt-sm-5 mt-5 md:mt-0 md:pt-0 ">
          {/* Responsive layout for search bar */}
          <div className="flex justify-center md:hidden mb-10">
            <form className="border border-solid border-[#82817F] rounded-[127px] flex items-center justify-between w-[100%] sm:w-[75%] mt-0 ps-3">
              {/* Search input */}
              <input
                className=" ff_poppins text-[14px] font-normal text-white bg-transparent py-2  lg:py-3 w-10/12 outline-none placeholder:text-white"
                type="text"
                placeholder="Search"
                required
              />
              {/* Search button */}
              <button
                aria-label="Search"
                type="submit"
                className="w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-[50%] p-2 flex justify-center items-center cursor-pointer common_bg_gradient_color">
                <SearchIcon />
              </button>
            </form>
          </div>
          {/* Hero content layout for larger screens */}
          <div className="flex flex-col-reverse md:flex-row flex-wrap justify-between md:items-center min-h-screen">
            {/* Left-side content */}
            <div className=" md:w-6/12 min-[1440px]:w-[55%] min-[1920px]:w-6/12  mt-[200px] md:mt-0">
              {/* Hero title */}
              <h1 className=" h-[65px] md:h-[70px] lg:h-[100px] xl:h-[140px] ff_poppins font-bold text-[31px] lg:text-[40px] xl:text-[55px] text-[#F9F5ED] leading-[142%] max-[375px]:max-w-[100%] min-[375px]:max-w-[85%] min-[425px]:max-w-[75%] sm:max-w-[90%] lg:max-w-[95%] 2xl:max-w-[85%] mb-6">
                C<span className="text"></span>
              </h1>
              {/* Hero description */}
              <p className=" ff_poppins text-[16px] font-semibold text-[#E5E2DE] pt-2 mb-0">
                Csras lectus nunc venenatis incidunt id quis malesuada
              </p>
              {/* Additional description */}
              <p className=" ff_poppins text-[16px] font-normal text-[#B5B5B5] pt-2 mb-0 leading-[160%] sm:pe-48 md:pe-0">
                Amet suscipit risus nulla libero porta purus id rhoncus emper
                faucibus risus cursus dibero ipsum tempus rhoncus dignissi massa
                cursus tortor.
              </p>
              {/* Search bar for larger screens */}
              <form className="border border-solid border-[#82817F] rounded-[127px] hidden md:flex items-center justify-between w-full md:w-[80%] lg:w-[70%] ps-4 mt-10">
                <input
                  className=" ff_poppins text-[14px] font-normal text-white bg-transparent py-2   lg:py-3 w-10/12 outline-none placeholder:text-white"
                  type="text"
                  placeholder="Search"
                  required
                />
                <button
                  type="submit"
                  className="w-[40px] h-[40px] lg:w-[50px] lg:h-[50px] rounded-[50%] p-2 flex justify-center items-center cursor-pointer common_bg_gradient_color">
                  <SearchIcon />
                </button>
              </form>
            </div>

            <div className="relative   md:w-6/12 min-[1440px]:w-[45%] min-[1920px]:w-6/12  md:min-h-[86vh] min-[1440px]:mr-[-150px] min-[1920px]:mr-0 ">
              {/* Left-side image slider */}
              <div className="md:w-6/12">
                {/* First set of images */}
                <div className="flex md:flex-col gap-5 key_frame_try md:py-3 absolute md:px-0 w-[180px]  lg:w-[240px] xl:w-[270px] md:end-[199px] lg:end-[260px] xl:end-[290px]">
                  {homeHeroLeftSlider.map((data, index) => {
                    return (
                      <img
                        key={index}
                        loading="lazy"
                        width={208}
                        height={161}
                        className=" w-full border-2 border-[#645E56] rounded-[8px]"
                        src={data.image}
                        alt="hero_img_slider"
                      />
                    );
                  })}
                </div>
                {/* Second set of images */}
                <div className="flex md:flex-col gap-5 key_frame_try2 md:py-3 absolute md:px-0 w-[180px] lg:w-[240px] xl:w-[270px] md:end-[199px] lg:end-[260px] xl:end-[290px]">
                  {homeHeroLeftSlider.map((data, index) => {
                    return (
                      <img
                        key={index}
                        width={208}
                        height={161}
                        className=" w-full border-2 border-[#645E56] rounded-[8px]"
                        src={data.image}
                        alt="hero_img_slider"
                        loading="lazy"
                      />
                    );
                  })}
                </div>
              </div>
              {/* Right image slider */}
              <div className="md:w-6/12 mt-[200px] md:mt-0">
                {/* First set of images */}
                <div className="flex md:flex-col gap-5 key_frame_try3 md:py-3 absolute md:px-0 w-[180px]  lg:w-[240px] xl:w-[270px] md:end-0">
                  {homeHeroRightSlider.map((data, index) => {
                    return (
                      <img
                        key={index}
                        width={208}
                        height={161}
                        className=" w-full border-2 border-[#645E56] rounded-[8px]"
                        src={data.image}
                        alt="hero_img_slider"
                        loading="lazy"
                      />
                    );
                  })}
                </div>
                {/* Second set of images */}
                <div className="flex md:flex-col gap-5 key_frame_try4 md:py-3 absolute md:px-0 w-[180px]  lg:w-[240px] xl:w-[270px] md:end-0">
                  {homeHeroRightSlider.map((data, index) => {
                    return (
                      <img
                        key={index}
                        width={208}
                        height={161}
                        className=" w-full border-2 border-[#645E56] rounded-[8px]"
                        src={data.image}
                        loading="lazy"
                        alt="hero_img_slider"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeHero;
