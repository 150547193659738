import React from "react";
import action_bg_deco from "../../assets/images/myaccount/XMLID_2_.webp";
import accountreelimg from "../../assets/images/watch_videos/watch_movie_reel.png";

import Faq from "../common/Faq";

const MyReview = () => {
  return (
    <div className="bg-my_account_bg bg-cover relative min-[1440px]:pt-[100px] ">
      <div className="bottom_blur_hero w-full h-[50px] absolute bottom-0 left-0"></div>

      <img
        className="absolute left-4 top-[117px] max-w-[70px] z-0 xl:block hidden"
        src={action_bg_deco}
        alt="hero-deco"
      />
      <img
        className="absolute right-0 top-[230px] max-w-[170px] md:block hidden"
        src={accountreelimg}
        alt="reel"
      />
      <div className="container md:max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto flex  pb-16 justify-start sm:px-4 lg:px-0 px-0 relative min-h-[calc(100vh-76px)] ">
        <div className="tab_data w-full">
          <div className="flex items-center justify-center h-full ">
            <p className="text-center text-white text-[24px] md:text-[40px] ff_poppins font-semibold pt-10">
              Coming Soon
            </p>
          </div>
        </div>
      </div>

      <div className="sm:pt-[60px] pt-5">
        <Faq />
      </div>
    </div>
  );
};

export default MyReview;
