import React, { useRef } from "react";
import Slider from "react-slick";

import {
  ChennalIcon,
  CommonLeftArrowIcon,
  CommonRightArrowIcon,
  ShareIcon,
} from "../common/Icon";

import { recentlyViewedSlider } from "../common/Helper";
import music_reel_left_side from "../../assets/images/png/right-side-position-music-reel-img.png";
import { useStateProvider } from "../common/StateContext";
import { Link, useNavigate } from "react-router-dom";

const RecentlyViewed = () => {
  // STATES VALUE IMPORT FROM PROVIDER
  const { setCardData, setActiveTab } = useStateProvider();
  // FOR REDIRECT TO ANY PAGE
  const navigate = useNavigate();

  // State to track active arrow direction
  // const [activeArrow, setActiveArrow] = useState("right");
  // Function to handle left arrow click
  const handleLeftArrow = () => {
    slider.current.slickPrev();
    // setActiveArrow("left");
  };
  // Function to handle right arrow click
  const handleRightArrow = () => {
    slider.current.slickNext();
    // setActiveArrow("right");
  };
  // Ref for the Slider component
  const slider = useRef(null);

  //SLIDER SETTING
  var settings = {
    dots: false,
    infinite: true,
    nextArrow: ".left-arrow",
    prevArrow: ".right-arrow",
    arrows: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 4,

    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          autoplay: true,
        },
      },
    ],
  };
  //FOR DIRECTION TO PRODUCT PAGE
  const onNavigateHandler = (value) => {
    localStorage.setItem("current-movie", JSON.stringify(value));
    const formattedTitle = value.title.replace(/\s+/g, "-");
    navigate(`/product-details?title=${formattedTitle}`);
    setCardData(value);
  };

  //SCROOL TOP FUNCTION
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      <section
        className="md:mt-10 lg:mt-[10px] xl:mt-[90px] pb-20 lg:pb-[130px] relative"
        id="recentlyviewed">
        <img
          className=" absolute start-0 top-[-70px] xl:top-[-65px] z-0 hidden xl:block"
          src={music_reel_left_side}
          alt="music_reel_left_side"
        />
        <span className="common_bg_gradient_color w-[200px] h-[200px] rounded-[343px] blur-[131px] absolute left-0 top-0 -z-10"></span>
        <div className="container  max-w-full xl:max-w-[1140px] 2xl:max-w-[1320px] mx-auto  z-10 relative">
          <h2 className="ff_poppins font-semibold text-[30px] md:text-[40px] text-white mb-0 text-center">
            Recently <span className=" common-text-gradient">Viewed</span>
          </h2>
          <div>
            <div className="relative pt-12 xl:pt-[50px] min-[1440px]:max-w-[900px] min-[1530px]:max-w-[980px] min-[1440px]:mx-auto 3xl:max-w-[1200px] min-[1920px]:max-w-full min-[1920px]:mx-0">
              <div className="justify-center gap-10 xl:gap-0 pt-10 xl:pt-0 hidden xl:flex">
                <button
                  aria-label="Slider Arrow"
                  onClick={handleLeftArrow}
                  className={`common-arrow left-arrow bg-transparent w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-[47%] left-[-5%]
                 `}>
                  <CommonLeftArrowIcon />
                </button>
                <button
                  aria-label="Slider Arrow"
                  onClick={handleRightArrow}
                  className={`common-arrow right-arrow bg-transparent w-10 h-10 rounded-[50%] duration-300 ease-in-out border border-solid border-[#f2cd75] hover:border-transparent xl:translate-y-1/2 xl:absolute top-[47%]  right-[-5%]
                 `}>
                  <CommonRightArrowIcon />
                </button>
              </div>
              <Slider className="pb-8 xl:pb-0" ref={slider} {...settings}>
                {recentlyViewedSlider.map((value, index) => {
                  return (
                    <div onClick={() => onNavigateHandler(value)} key={index}>
                      <div className=" rounded-xl cursor-pointer card_backdrop_filter border border-solid border-[#474643] p-2 mx-[10px] mb-2">
                        <div
                          className={`flex bg-cover bg-no-repeat rounded-xl bg-center h-[439px] relative px-2 `}
                          style={{
                            backgroundImage: `url(${value.image})`,
                          }}>
                          <div className=" flex  flex-col flex-grow justify-end">
                            <h5 className="ff_poppins text-[16px]  font-semibold text-white opacity-90  mb-0">
                              {value.title}
                            </h5>
                            <span className="flex items-center text-2xl text-white">
                              <ChennalIcon />+
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col w-full gap-2 sm:gap-4 justify-between mt-4">
                          <span className="flex w-full">
                            <button className=" common_bg_gradient_color ff_inter font-semibold text-[16px] text-white border w-full py-[11px] px-1 lg:px-4 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex justify-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                              Add a Watchlist
                            </button>
                          </span>
                          <span className="flex w-full">
                            <button className=" common_bg_gradient_color ff_inter font-semibold text-[16px] text-white border w-full py-[11px] px-1 lg:px-4 rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex justify-center items-center relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                              <ShareIcon />
                              Share
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
            <div className="flex justify-center mt-4 md:mt-6 lg:mt-0">
              <Link
                aria-label="All Viewed"
                to="/library"
                onClick={() => {
                  setActiveTab("All");
                  scrollToTop();
                }}
                className=" ff_inter font-semibold text-[16px] text-white common_bg_gradient_color border py-[10px] px-[14px]  mt-10 lg:mt-[52px] rounded overflow-hidden  border-solid border-[#f2cd75] hover:border  border-transparent  after:bg-black  z-10 flex items-center justify-between relative duration-300 ease-linear after:absolute after:w-full after:h-0 after:top-0 after:left-0 after:z-[-1] after:duration-300 after:ease-linear hover:after:h-full hover:after:bottom-0">
                All Viewed
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default RecentlyViewed;
