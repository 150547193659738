// Fan favorites
import fan_favorites_card_img1 from "../../assets/images/fanFavorites/fan_favorites_card_img1.webp";
import fan_favorites_card_img2 from "../../assets/images/fanFavorites/fan_favorites_card_img2.webp";
import fan_favorites_card_img3 from "../../assets/images/fanFavorites/fan_favorites_card_img3.webp";
import fan_favorites_card_img4 from "../../assets/images/fanFavorites/fan_favorites_card_img4.webp";
import moviesposture from "../../assets/images/mostratedmovies/robin_hood.webp";

// FEATURED-TODAY
import featuredSliderImg1 from "../../assets/images/featuredToday/featured_slider_img1.webp";
import featuredSliderImg2 from "../../assets/images/featuredToday/featured_slider_img2.webp";
import featuredSliderImg3 from "../../assets/images/featuredToday/featured_slider_img3.webp";
// Watch Videos
import movieImage1 from "../../assets/images/watch_videos/guardiansofglaxy.webp";
import movieImage2 from "../../assets/images/watch_videos/ps-1.webp";

// ITEM REALTED SUGGESTED ITEM DATA
import suggestImg1 from "../../assets/images/webp/suggest-item-1.webp";
import suggestImg2 from "../../assets/images/webp/seggest2.webp";
import suggestImg3 from "../../assets/images/webp/seggest3.webp";
import suggestImg4 from "../../assets/images/webp/seggest4.webp";
import suggestImg5 from "../../assets/images/webp/seggest5.webp";
import suggestImg6 from "../../assets/images/webp/seggest6.webp";
import suggestImg7 from "../../assets/images/webp/seggest7.webp";
import suggestImg8 from "../../assets/images/webp/seggest8.webp";
import suggestImg9 from "../../assets/images/webp/seggest9.webp";
import suggestImg10 from "../../assets/images/webp/seggest10.webp";
import suggestImg11 from "../../assets/images/webp/seggest11.webp";
import suggestImg12 from "../../assets/images/webp/seggest12.webp";
import suggestImg13 from "../../assets/images/webp/seggest13.webp";
import suggestImg14 from "../../assets/images/webp/seggest14.webp";
import suggestImg15 from "../../assets/images/webp/seggest15.webp";
import suggestImg16 from "../../assets/images/webp/seggest16.webp";
import suggestImg17 from "../../assets/images/webp/seggest17.webp";
import suggestImg18 from "../../assets/images/webp/seggest18.webp";
import suggestImg19 from "../../assets/images/webp/seggest19.webp";
import suggestImg20 from "../../assets/images/webp/seggest20.webp";

import profile from "../../assets/images/webp/jhoe-deo-profile.webp";
import profile2 from "../../assets/images/webp/profile2.webp";
import profile3 from "../../assets/images/webp/profile3.webp";
import profile4 from "../../assets/images/webp/profile4.webp";
import profile5 from "../../assets/images/webp/profile5.webp";
import profile6 from "../../assets/images/webp/profile6.webp";
import profile7 from "../../assets/images/webp/profile7.webp";
import profile8 from "../../assets/images/webp/profile8.webp";
import profile9 from "../../assets/images/webp/profile9.webp";
import profile10 from "../../assets/images/webp/profile10.webp";
import profile11 from "../../assets/images/webp/profile11.webp";
import profile12 from "../../assets/images/webp/profile12.webp";
import profile13 from "../../assets/images/webp/profile13.webp";
import profile14 from "../../assets/images/webp/profile14.webp";
import profile15 from "../../assets/images/webp/profile15.webp";

// RECENTLY VIEWED
import recently_viewed_card_img1 from "../../assets/images/recentlyViewed/recently-viewed-1.webp";
import recently_viewed_card_img2 from "../../assets/images/recentlyViewed/recently-viewed-2.webp";
import recently_viewed_card_img3 from "../../assets/images/recentlyViewed/recently-viewed-3.webp";
import recently_viewed_card_img4 from "../../assets/images/recentlyViewed/recently-viewed-4.webp";

// MOST POPULAR
import popularSliderImg1 from "../../assets/images/mostPopular/popular-slider-1.webp";
import popularSliderImg2 from "../../assets/images/mostPopular/popular-slider-2.webp";
import popularSliderImg3 from "../../assets/images/mostPopular/popular-slider-3.webp";
// INDIAN TV SHOW
import IndianShowSliderImg1 from "../../assets/images/indianTvShow/indian-tv-show1.webp";
import IndianShowSliderImg2 from "../../assets/images/indianTvShow/indian-tv-show2.webp";
import IndianShowSliderImg3 from "../../assets/images/indianTvShow/indian-tv-show3.webp";
// ADVENTURE SLIDER
import adventureSliderImg1 from "../../assets/images/adventureSliderImages/adventure-slider-img1.webp";
import adventureSliderImg2 from "../../assets/images/adventureSliderImages/adventure-slider-img2.webp";
import adventureSliderImg3 from "../../assets/images/adventureSliderImages/adventure-slider-img3.webp";
// ROMANCE SLIDER
import romanceSliderImg1 from "../../assets/images/romance/romance-slider-img1.webp";
import romanceSliderImg2 from "../../assets/images/romance/romance-slider-img2.webp";
import romanceSliderImg3 from "../../assets/images/romance/romance-slider-img3.webp";
//mostRatedMovies Library
import rankedmovie1 from "../../assets/images/myaccount/library/most-rank1.webp";
import rankedmovie2 from "../../assets/images/myaccount/library/most-rank2.webp";
import rankedmovie3 from "../../assets/images/myaccount/library/most-rank3.webp";
import rankedmovie4 from "../../assets/images/myaccount/library/most-rank4.webp";
// COMEDIE SLIDER
import comedieSliderImg1 from "../../assets/images/comidesSliderImages/comide-slider-img1.webp";
import comedieSliderImg2 from "../../assets/images/comidesSliderImages/comide-slider-img2.webp";
import comedieSliderImg3 from "../../assets/images/comidesSliderImages/comide-slider-img3.webp";

// PlayListMovie
import playlist1 from "../../assets/images/myaccount/library/platlist1.webp";
import playlist2 from "../../assets/images/myaccount/library/platlist2.webp";
import playlist3 from "../../assets/images/myaccount/library/platlist3.webp";
import playlist4 from "../../assets/images/myaccount/library/platlist4.webp";

// likeVideoLibrary
import likedvideo1 from "../../assets/images/myaccount/library/liked1.webp";
import likedvideo2 from "../../assets/images/myaccount/library/liked2.webp";
import likedvideo3 from "../../assets/images/myaccount/library/liked3.webp";
import likedvideo4 from "../../assets/images/myaccount/library/liked4.webp";

// HomeHeroSlider
import heroLeftImage1 from "../../assets/images/homePageHero/homepage_hero_left_slider_img1.webp";
import heroLeftImage2 from "../../assets/images/homePageHero/homepage_hero_left_slider_img2.webp";
import heroLeftImage3 from "../../assets/images/homePageHero/homepage_hero_left_slider_img3.webp";
import heroRightImage1 from "../../assets/images/homePageHero/homepage_hero_right_slider_img1.webp";
import heroRightImage2 from "../../assets/images/homePageHero/homepage_hero_right_slider_img2.webp";
import heroRightImage3 from "../../assets/images/homePageHero/homepage_hero_right_slider_img3.webp";

// FRIEND BUTTON ICON IMAGE
import nitroIcon from "../../assets/images/svg/nitro_icon.svg";

//  SIDEBAR ICONS
import {
  AnnouncementIcon,
  FriendIcon,
  HistoryIcon,
  HomeIcon,
  LibrayIcon,
  MyAccountIcon,
  MyCommunitiesIcon,
  MyFriendsIcon,
  MyReviewIcon,
  ResourceIcon,
  ShopeIcon,
  SignOutIcon,
  SubscriptionIcon,
  TrendingIcon,
} from "../../components/common/Icon";

// FAN-FAVORITES SLIDER DATA
export const fanFavoriteSlider = [
  {
    title: "In elit nullam sit quam",
    image: fan_favorites_card_img1,
    rating: "5",
  },
  {
    title: "Ultrices donec evjvde",
    image: fan_favorites_card_img2,
    rating: "5",
  },
  {
    title: "Duis sed donec morbi",
    image: fan_favorites_card_img3,
    rating: "4",
  },
  {
    title: "Nisi maecenas non",
    image: fan_favorites_card_img4,
    rating: "4",
  },

  {
    title: "Duis sed donec morbi",
    image: fan_favorites_card_img3,
    rating: "4",
  },
  {
    title: "Nisi maecenas non",
    image: fan_favorites_card_img4,
    rating: "4",
  },
  {
    title: "In elit nullam sit quam",
    image: fan_favorites_card_img1,
    rating: "5",
  },
  {
    title: "Ultrices donec evjvde",
    image: fan_favorites_card_img2,
    rating: "5",
  },
];
// FEATURED-TODAY DATA
export const featuredSlider = [
  {
    title: "Sed felis nisl et ultrices",
    description: "Arcu egestas ac tortor non",
    image: featuredSliderImg1,
    rating: "4",
  },
  {
    title: "Sed felis nisl et ultrices",
    description: "Arcu egestas ac tortor non",
    image: featuredSliderImg2,
    rating: "4",
  },
  {
    title: "Sed felis nisl et ultrices",
    description: "Arcu egestas ac tortor non",
    image: featuredSliderImg3,
    rating: "4",
  },
  {
    title: "Sed felis nisl et ultrices",
    description: "Arcu egestas ac tortor non",
    image: featuredSliderImg1,
    rating: "4",
  },
  {
    title: "Sed felis nisl et ultrices",
    description: "Arcu egestas ac tortor non",
    image: featuredSliderImg2,
    rating: "4",
  },
  {
    title: "Sed felis nisl et ultrices",
    description: "Arcu egestas ac tortor non",
    image: featuredSliderImg3,
    rating: "4",
  },
];
// FAQ DATA
export const faqList = [
  {
    title: "Q1. Fermentum in hac mattis viverra orem commodo?",
    description:
      "Mknki consequat sagittis elementum tincidunt sed quis dictum tiam arcu tempor pulvinar volutpat massa eget praesent. ",
  },
  {
    title: "Q2. Viverra ipsum gravida etiam sem iaculis?",
    description:
      "Mknki consequat sagittis elementum tincidunt sed quis dictum tiam arcu tempor pulvinar volutpat massa eget praesent. ",
  },
  {
    title: "Q3. Morbi curabitur suscipit id posuere?",
    description:
      "Mknki consequat sagittis elementum tincidunt sed quis dictum tiam arcu tempor pulvinar volutpat massa eget praesent. ",
  },
  {
    title: "Q4. Cursus sit sit velsem accumsan varius sed auris?",
    description:
      "Mknki consequat sagittis elementum tincidunt sed quis dictum tiam arcu tempor pulvinar volutpat massa eget praesent. ",
  },
  {
    title: "Q5. Dui tincidunt sed eu convallis quis ultrices?",
    description:
      "Mknki consequat sagittis elementum tincidunt sed quis dictum tiam arcu tempor pulvinar volutpat massa eget praesent. ",
  },
  {
    title: "Q6. Arcu lorem faucibus eu integer orci pretium faucibus?",
    description:
      "Mknki consequat sagittis elementum tincidunt sed quis dictum tiam arcu tempor pulvinar volutpat massa eget praesent. ",
  },
];
// Watch Videos SLIDER DATA
export const watchVideosSlider = [
  {
    image: movieImage1,
    title: "Id tristique imperdiet volutpat lectus nam.",
    url: "https://www.youtube.com/embed/JqcncLPi9zw?si=txaaPpNiQOIR2MzS",
    rating: "4",
  },
  {
    image: movieImage2,
    title: "Id tristique imperdiet volutpat lectus nam.",
    url: "https://www.youtube.com/embed/kwy1xpqMHsM?si=gdnyMSY5AFIq3gta",
    rating: "5",
  },
  {
    image: movieImage1,
    title: "Id tristique imperdiet volutpat lectus nam.",
    url: "https://www.youtube.com/embed/JqcncLPi9zw?si=txaaPpNiQOIR2MzS",
    rating: "4",
  },
  {
    image: movieImage2,
    title: "Id tristique imperdiet volutpat lectus nam.",
    url: "https://www.youtube.com/embed/kwy1xpqMHsM?si=gdnyMSY5AFIq3gta",
    rating: "4",
  },
];
// PRODUCT-RELATED-SUGGESTED-ITEM-DATA
export const relatedItems = [
  {
    image: suggestImg1,
    url: "https://www.example.com/video1.mp4",
    title: "Aliquet ut adipiscing volutpat fermentum nisl massa",
    trailer: "YM Movie Trailer",
    views: "1900000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg2,
    url: "https://www.example.com/video1.mp4",
    title: "Tellus pulvinar sit interdum sed. Ullamcorper semper sodales.",
    trailer: "YM Movie Trailer",
    views: "1100000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg3,
    url: "https://www.example.com/video1.mp4",
    title: "Ac vitae est ut parturient. Elementum nisi ullamcorper sit.",
    trailer: "YM Movie Trailer",
    views: "2300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg4,
    url: "https://www.example.com/video1.mp4",
    title: "Lectus sed lobortis aenean amet lectus etiam.",
    trailer: "YM Movie Trailer",
    views: "2900000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg5,
    url: "https://www.example.com/video1.mp4",
    title: "Sed atvel lorem enim verra lobortis sit ridiculus morbi.",
    trailer: "YM Movie Trailer",
    views: "4700000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg6,
    url: "https://www.example.com/video1.mp4",
    title: "Pharetra gravida quam tempor commodo mauris nibh aturna.",
    trailer: "YM Movie Trailer",
    views: "9200000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg7,
    url: "https://www.example.com/video1.mp4",
    title: "Duis vestibulum enim massa neque viverra sodales.",
    trailer: "YM Movie Trailer",
    views: "3300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg8,
    url: "https://www.example.com/video1.mp4",
    title: "Est proin euismod sed egetsit dignissim.",
    trailer: "YM Movie Trailer",
    views: "1300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg9,
    url: "https://www.example.com/video1.mp4",
    title: "Tincidunt sit nisl mattis sit porttitor ultricies.",
    trailer: "YM Movie Trailer",
    views: "7200000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg10,
    url: "https://www.example.com/video1.mp4",
    title: "Quis cras ut pellentesque morbi vel etget tellus blandit.",
    trailer: "YM Movie Trailer",
    views: "3300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg11,
    url: "https://www.example.com/video1.mp4",
    title: "Feugiat dictum elementum lacus sed in egestas arcu.",
    trailer: "YM Movie Trailer",
    views: "6600000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg12,
    url: "https://www.example.com/video1.mp4",
    title: "Bibendum urna elementum est mattis scelerisque.",
    trailer: "YM Movie Trailer",
    views: "5100000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg13,
    url: "https://www.example.com/video1.mp4",
    title: "Scelerisque lorem sagittis amet nunc.",
    trailer: "YM Movie Trailer",
    views: "4600000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg14,
    url: "https://www.example.com/video1.mp4",
    title: "Rhoncus habitasse imperdiet pharetra amet sapien.",
    trailer: "YM Movie Trailer",
    views: "5300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg15,
    url: "https://www.example.com/video1.mp4",
    title: "Cursus at arcu sed nunc bibendum aliquet scelerisque.",
    trailer: "YM Movie Trailer",
    views: "8900000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg16,
    url: "https://www.youtube.com/live/HIj8wU_rGIU?feature=shared",
    title: "Nulla massa lacus nullam molestie fermentum rhoncus.",
    trailer: "YM Movie Trailer",
    views: "8400000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg17,
    url: "https://youtu.be/U6A7Iv0RbdA?feature=shared",
    title: "Cras sed amet magna non cras urna leo eget eu lobortis at.",
    trailer: "YM Movie Trailer",
    views: "9300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg18,
    url: "https://youtu.be/4ncItDgguAc?feature=shared",
    title: "Sit bibendum eros sit justo volutpat. Neque facilisis.",
    trailer: "YM Movie Trailer",
    views: "6100000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg19,
    url: "https://www.example.com/video1.mp4",
    title: "A pharetra pulvinar cras pellentesque turpis scelerisque.",
    trailer: "YM Movie Trailer",
    views: "4300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg20,
    url: "https://www.example.com/video1.mp4",
    title: "Nulla bibendum id lacinia leorna in tellus elementum.",
    trailer: "YM Movie Trailer",
    views: "2000000",
    outTime: "1 month ago",
  },
];
//PRODUCT-RELETED-SUGGESTED-ITEM DATA NEXT TAB
export const trailerMap = [
  {
    image: suggestImg5,
    title: "Sed atvel lorem enim verra lobortis sit ridiculus morbi.",
    trailer: "YM Movie Trailer",
    views: "4700000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg6,
    title: "Pharetra gravida quam tempor commodo mauris nibh aturna.",
    trailer: "YM Movie Trailer",
    views: "9200000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg7,
    title: "Duis vestibulum enim massa neque viverra sodales.",
    trailer: "YM Movie Trailer",
    views: "3300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg8,
    title: "Est proin euismod sed egetsit dignissim.",
    trailer: "YM Movie Trailer",
    views: "1300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg1,
    title: "Aliquet ut adipiscing volutpat fermentum nisl massa",
    trailer: "YM Movie Trailer",
    views: "1900000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg9,
    title: "Tincidunt sit nisl mattis sit porttitor ultricies.",
    trailer: "YM Movie Trailer",
    views: "7200000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg10,
    title: "Quis cras ut pellentesque morbi vel etget tellus blandit.",
    trailer: "YM Movie Trailer",
    views: "3300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg11,
    title: "Feugiat dictum elementum lacus sed in egestas arcu.",
    trailer: "YM Movie Trailer",
    views: "6600000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg20,
    title: "Nulla bibendum id lacinia leorna in tellus elementum.",
    trailer: "YM Movie Trailer",
    views: "2000000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg12,
    title: "Bibendum urna elementum est mattis scelerisque.",
    trailer: "YM Movie Trailer",
    views: "51200000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg13,
    title: "Scelerisque lorem sagittis amet nunc.",
    trailer: "YM Movie Trailer",
    views: "4600000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg14,
    title: "Rhoncus habitasse imperdiet pharetra amet sapien.",
    trailer: "YM Movie Trailer",
    views: "5300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg15,
    title: "Cursus at arcu sed nunc bibendum aliquet scelerisque.",
    trailer: "YM Movie Trailer",
    views: "8900000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg2,
    title: "Tellus pulvinar sit interdum sed. Ullamcorper semper sodales.",
    trailer: "YM Movie Trailer",
    views: "1100000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg3,
    title: "Ac vitae est ut parturient. Elementum nisi ullamcorper sit.",
    trailer: "YM Movie Trailer",
    views: "2300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg4,
    title: "Lectus sed lobortis aenean amet lectus etiam.",
    trailer: "YM Movie Trailer",
    views: "2900000",
    outTime: "1 month ago",
  },

  {
    image: suggestImg17,
    title: "Cras sed amet magna non cras urna leo eget eu lobortis at.",
    trailer: "YM Movie Trailer",
    views: "9300000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg18,
    title: "Sit bibendum eros sit justo volutpat. Neque facilisis.",
    trailer: "YM Movie Trailer",
    views: "6100000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg16,
    title: "Nulla massa lacus nullam molestie fermentum rhoncus.",
    trailer: "YM Movie Trailer",
    views: "8400000",
    outTime: "1 month ago",
  },
  {
    image: suggestImg19,
    title: "A pharetra pulvinar cras pellentesque turpis scelerisque.",
    trailer: "YM Movie Trailer",
    views: "4300000",
    outTime: "1 month ago",
  },
];

//COMMENT SECTION DATA
export const relatedItemComments = [
  {
    image: profile,
    date: "24-may-2023",
    name: "John Doe",
    comment:
      "Ultrices diam at mauris id adipiscing. Ipsum et lacus sed mauris. Pellentesque orci nulla sem donec adipiscing.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile2,
    date: "24-may-2023",
    name: "Brooklyn Simmons",
    comment:
      "Sapien vestibulum augue eget tempor viverra lacus lacinia volutpat egestas vulputate massa arcu.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile3,
    date: "24-may-2023",
    name: "Wade Warren",
    comment: "Commodo libero laoreet pellentesque eget lacus.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile4,
    date: "24-may-2023",
    name: "Courtney Henry",
    comment:
      "Adipiscing augue scelerisque donec etiam aliquam acilisis purus diam apien condimentum massa enim.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile5,
    date: "24-may-2023",
    name: "Darlene Robertson",
    comment:
      "Dui maecenas erat viverra viverra bibendum odio. Fermentum justo nunc in pulvinar mauris quisque.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile6,
    date: "24-may-2023",
    name: "Jane Cooper",
    comment: "Maecenas euismod sit dui commodo nec lobortis.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile7,
    date: "24-may-2023",
    name: "Jerome Bell",
    comment:
      "Nunc donec tincidunt tincidunt consequat. Nunc cras vitae libero id morbi feugiat nunc vel.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile8,
    date: "24-may-2023",
    name: "Marvin McKinney",
    comment: "Vel amet sed a venenatis posuere.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile9,
    date: "24-may-2023",
    name: "Guy Hawkins",
    comment: "Mi nec gravida commodo mollis montes felis ultricies ipsum est.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile10,
    date: "24-may-2023",
    name: "Ralph Edwards",
    comment:
      "Semper nisi dui gravida quisque vitae gravida sed vel. Risus cras risus nec quisque.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile11,
    date: "24-may-2023",
    name: "Albert Flores",
    comment:
      "Tellus augue viverra hendrerit rhoncus vulputate volutpat cursus urna.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile12,
    date: "24-may-2023",
    name: "Leslie Alexander",
    comment: "Sed tortor aliquam ac massa molestie iaculis vestibulum purus.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile13,
    date: "24-may-2023",
    name: "Robert Fox",
    comment:
      "Tellus morbi condimentum vitae met sed felis cras tortor enim feugiat in scelerimper ornare vitae scelerisque.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile14,
    date: "24-may-2023",
    name: "Dianne Russell",
    comment: "Tincidunt nunc amet enim amet iaculis.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
  {
    image: profile15,
    date: "24-may-2023",
    name: "Ronald Richards",
    comment:
      "Aliquet tortor duis hendrerit magna amet. Sed molestie sed sit magna.",
    like: 0,
    dislike: 1,
    reply: "Reply",
  },
];
// RECENTLY-VIEWED DATA
export const recentlyViewedSlider = [
  {
    title: "Nam lacus leo blandit morbi",
    image: recently_viewed_card_img1,
  },
  {
    title: "Risus at in euismod fringilla",
    image: recently_viewed_card_img2,
  },
  {
    title: "Purus mollis vitae magna",
    image: recently_viewed_card_img3,
  },
  {
    title: "Leo id nisi nulla adipiscing",
    image: recently_viewed_card_img4,
  },
  {
    title: "Nam lacus leo blandit morbi",
    image: recently_viewed_card_img1,
  },
  {
    title: "Risus at in euismod fringilla",
    image: recently_viewed_card_img2,
  },
  {
    title: "Purus mollis vitae magna",
    image: recently_viewed_card_img3,
  },
  {
    title: "Leo id nisi nulla adipiscing",
    image: recently_viewed_card_img4,
  },
];
// REVIEWS RATING DATA
export const reviewList = [
  {
    title: "Sed felis nisl et ultrices",
    date: "22/05/2023",
    rating: "5",
    description:
      "Odio eget vestibulum pulvinar interdum nisl pellentesque imperdiet ut mattis. Duis vel lacus nulla dapibus auctor arcu viverra eget sed. Laoreet in elit bibendum quisque dictum tellus ipsum. Lacus sem mus non vel suspendisse posuere elit.",
  },
  {
    title: "Sed felis nisl et ultrices",
    date: "22/05/2023",
    rating: "5",
    description:
      "Odio eget vestibulum pulvinar interdum nisl pellentesque imperdiet ut mattis. Duis vel lacus nulla dapibus auctor arcu viverra eget sed. Laoreet in elit bibendum quisque dictum tellus ipsum. Lacus sem mus non vel suspendisse posuere elit.",
  },
];

//MOST-RARED SLIDER DATA
export const mostRatedSliderHome = [
  {
    image: moviesposture,
    title: "Robin Hood",
    description:
      " Odio elit nulla tellus elementum. Odio proin dignissim venenatis ullamcorper. Sed enim et sed lorem. Quis et potenti tortor arcu egestas ac tortor non. Aliquet sem varius in eget sodales nisl pretium diam. Lorem mauris aliquet massa aliquam ultricies imperdiet phasellus sociis urna. Faucibus quis dui etiam hendrerit amet pellentesque pellentesque. ",
    rating: "4",
  },
  {
    image: moviesposture,
    title: "Most Wanted",
    description:
      " Odio elit nulla tellus elementum. Odio proin dignissim venenatis ullamcorper. Sed enim et sed lorem. Quis et potenti tortor arcu egestas ac tortor non. Aliquet sem varius in eget sodales nisl pretium diam. Lorem mauris aliquet massa aliquam ultricies imperdiet phasellus sociis urna. Faucibus quis dui etiam hendrerit amet pellentesque pellentesque.",
    rating: "4",
  },
  {
    image: moviesposture,
    title: "Superman",
    description:
      " Odio elit nulla tellus elementum. Odio proin dignissim venenatis ullamcorper. Sed enim et sed lorem. Quis et potenti tortor arcu egestas ac tortor non. Aliquet sem varius in eget sodales nisl pretium diam. Lorem mauris aliquet massa aliquam ultricies imperdiet phasellus sociis urna. Faucibus quis dui etiam hendrerit amet pellentesque pellentesque.",
    rating: "4",
  },
];
// MOST RATED SECOND SLIDER DATA
export const mostRatedSlider = [
  {
    image: moviesposture,
    title: "There’s even more to watch.",
    description:
      " Odio elit nulla tellus elementum. Odio proin dignissim venenatis ullamcorper. Sed enim et sed lorem. Quis et potenti tortor arcu egestas ac tortor non. Aliquet sem varius in eget sodales nisl pretium diam. Lorem mauris aliquet massa aliquam ultricies imperdiet phasellus sociis urna. Faucibus quis dui etiam hendrerit amet pellentesque pellentesque.",
    rating: "4",
  },
  {
    image: moviesposture,
    title: "There’s to watch.",
    description:
      " Odio elit nulla tellus elementum. Odio proin dignissim venenatis ullamcorper. Sed enim et sed lorem. Quis et potenti tortor arcu egestas ac tortor non. Aliquet sem varius in eget sodales nisl pretium diam. Lorem mauris aliquet massa aliquam ultricies imperdiet phasellus sociis urna. Faucibus quis dui etiam hendrerit amet pellentesque pellentesque.",
    rating: "4",
  },
  {
    image: moviesposture,
    title: "More to watch.",
    description:
      " Odio elit nulla tellus elementum. Odio proin dignissim venenatis ullamcorper. Sed enim et sed lorem. Quis et potenti tortor arcu egestas ac tortor non. Aliquet sem varius in eget sodales nisl pretium diam. Lorem mauris aliquet massa aliquam ultricies imperdiet phasellus sociis urna. Faucibus quis dui etiam hendrerit amet pellentesque pellentesque.",
    rating: "4",
  },
];
// MOST POPULAR SLIDER DATA HERE
export const mostPopularSlider = [
  {
    image: popularSliderImg1,
    title: "Id tristique imperdiet",
    rating: "4",
  },
  {
    image: popularSliderImg2,
    title: "Pellentesque vitae egestas",
    rating: "4",
  },
  {
    image: popularSliderImg3,
    title: "Ridiculus vivamus",
    rating: "4",
  },
  {
    image: popularSliderImg1,
    title: "Id tristique imperdiet",
    rating: "4",
  },
  {
    image: popularSliderImg2,
    title: "Pellentesque vitae egestas",
    rating: "4",
  },
  {
    image: popularSliderImg3,
    title: "Ridiculus vivamus",
    rating: "4",
  },
];
// INDIAN TV SHOW SLIDER DATA HERE
export const indianTvShowSlider = [
  {
    image: IndianShowSliderImg1,
    title: "Mauris nec dictumst",
    rating: "4",
  },
  {
    image: IndianShowSliderImg2,
    title: "Lacinia justo urna",
    rating: "4",
  },
  {
    image: IndianShowSliderImg3,
    title: "Pellentesque imperdiet",
    rating: "4",
  },
  {
    image: IndianShowSliderImg1,
    title: "Mauris nec dictumst",
    rating: "4",
  },
  {
    image: IndianShowSliderImg2,
    title: "Lacinia justo urna",
    rating: "4",
  },
  {
    image: IndianShowSliderImg3,
    title: "Pellentesque imperdiet",
    rating: "4",
  },
];
// ADVENTURE SLIDER DATA HERE
export const adventureSlider = [
  {
    image: adventureSliderImg1,
    title: "Fringilla arcu scelerisque",
    rating: "4",
  },
  {
    image: adventureSliderImg2,
    title: "Aetiam risus mgnvitae",
    rating: "4",
  },
  {
    image: adventureSliderImg3,
    title: "Malesuada faucibus",
    rating: "4",
  },
  {
    image: adventureSliderImg1,
    title: "Fringilla arcu scelerisque",
    rating: "4",
  },
  {
    image: adventureSliderImg2,
    title: "Aetiam risus mgnvitae",
    rating: "4",
  },
  {
    image: adventureSliderImg3,
    title: "Malesuada faucibus",
    rating: "4",
  },
];
// ROMANCE SLIDER DATA HERE
export const romanceSlider = [
  {
    image: romanceSliderImg1,
    title: "Magna etiam",
    rating: "4",
  },
  {
    image: romanceSliderImg2,
    title: "Dictum nunc",
    rating: "4",
  },
  {
    image: romanceSliderImg3,
    title: "Suspendisse ultrices ",
    rating: "4",
  },
  {
    image: romanceSliderImg1,
    title: "Magna etiam",
    rating: "4",
  },
  {
    image: romanceSliderImg2,
    title: "Dictum nunc",
    rating: "4",
  },
  {
    image: romanceSliderImg3,
    title: "Suspendisse ultrices ",
    rating: "4",
  },
];
// COMEDIE SLIDER DATA HERE
export const comedieSlider = [
  {
    image: comedieSliderImg1,
    title: "Suspendisse ullamcorper",
    rating: "4",
  },
  {
    image: comedieSliderImg2,
    title: "Libero enim",
    rating: "4",
  },
  {
    image: comedieSliderImg3,
    title: "Elementum ",
    rating: "4",
  },
  {
    image: comedieSliderImg1,
    title: "Suspendisse ullamcorper",
    rating: "4",
  },
  {
    image: comedieSliderImg2,
    title: "Libero enim",
    rating: "4",
  },
  {
    image: comedieSliderImg3,
    title: "Elementum ",
    rating: "4",
  },
];
//MOST RANKED MUSIC LIBRARY DATA
export const mostRankedLibrary = [
  {
    image: rankedmovie1,
    title: "Sed atvelverra lobortis.",
    views: "5300000",
    realsetime: "2 month ago",
    rating: "5",
  },
  {
    image: rankedmovie2,
    title: "Sed atvelverra lobortis.",
    views: "4700000",
    realsetime: "1 month ago",
    rating: "5",
  },
  {
    image: rankedmovie3,
    title: "Simply of the printing",
    views: "3700000",
    realsetime: "1 Week ago",
    rating: "5",
  },
  {
    image: rankedmovie4,
    title: "Established fact that reader.",
    views: "6100000",
    realsetime: "3 month ago",
    rating: "5",
  },
  {
    image: rankedmovie1,
    title: "Sed atvelverra lobortis.",
    views: "5300000",
    realsetime: "2 month ago",
    rating: "5",
  },
  {
    image: rankedmovie2,
    title: "Sed atvelverra lobortis.",
    views: "4700000",
    realsetime: "1 month ago",
    rating: "5",
  },
  {
    image: rankedmovie3,
    title: "Simply of the printing",
    views: "3700000",
    realsetime: "1 Week ago",
    rating: "5",
  },
  {
    image: rankedmovie4,
    title: "Established fact that reader.",
    views: "6100000",
    realsetime: "3 month ago",
    rating: "5",
  },
];
//PLAY LIST LIBRARY DATA
export const playlistLibrary = [
  {
    image: playlist1,
    title: "Printer took a galley of type.",
    views: "5300000",
    realsetime: "2 month ago",
    rating: "5",
  },
  {
    image: playlist2,
    title: "Ipsum is that it has less.",
    views: "4700000",
    realsetime: "1 month ago",
    rating: "5",
  },
  {
    image: playlist3,
    title: "The standard chunk.",
    views: "3700000",
    realsetime: "1 Week ago",
    rating: "5",
  },
  {
    image: playlist4,
    title: "Therefore  repetition.",
    views: "6100000",
    realsetime: "3 month ago",
    rating: "5",
  },
  {
    image: playlist1,
    title: "Printer took a galley of type.",
    views: "5300000",
    realsetime: "2 month ago",
    rating: "5",
  },
  {
    image: playlist2,
    title: "Ipsum is that it has less.",
    views: "4700000",
    realsetime: "1 month ago",
    rating: "5",
  },
  {
    image: playlist3,
    title: "The standard chunk.",
    views: "3700000",
    realsetime: "1 Week ago",
    rating: "5",
  },
  {
    image: playlist4,
    title: "Therefore  repetition.",
    views: "6100000",
    realsetime: "3 month ago",
    rating: "5",
  },
];
//LIKE VIDEO LIBRARY DATA
export const likeVideoLibrary = [
  {
    image: likedvideo1,
    title: "Contrary to popular belief.",
    views: "5300000",
    realsetime: "2 month ago",
    rating: "5",
  },
  {
    image: likedvideo2,
    title: "Going through the cites.",
    views: "4700000",
    realsetime: "1 month ago",
    rating: "5",
  },
  {
    image: likedvideo3,
    title: "look like readable English.",
    views: "3700000",
    realsetime: "1 Week ago",
    rating: "5",
  },
  {
    image: likedvideo4,
    title: "Contrary to popular belief.",
    views: "6100000",
    realsetime: "3 month ago",
    rating: "5",
  },
  {
    image: likedvideo1,
    title: "Contrary to popular belief.",
    views: "5300000",
    realsetime: "2 month ago",
    rating: "5",
  },
  {
    image: likedvideo2,
    title: "Going through the cites.",
    views: "4700000",
    realsetime: "1 month ago",
    rating: "5",
  },
  {
    image: likedvideo3,
    title: "look like readable English.",
    views: "3700000",
    realsetime: "1 Week ago",
    rating: "5",
  },
  {
    image: likedvideo4,
    title: "Contrary to popular belief.",
    views: "6100000",
    realsetime: "3 month ago",
    rating: "5",
  },
];
// FOOTER LINKS
export const footerList = [
  {
    title: "Home",
    footerLinks: [
      {
        link: "Watch videos",
        url: "/#watchvideo",
      },
      {
        link: "Fan favorites",
        url: "/#fanfavorites",
      },
      {
        link: "Most rated movies",
        url: "/#mostrated",
      },
      {
        link: "Featured",
        url: "/#featured",
      },
      {
        link: "Reviews & Rating",
        url: "/#reviews",
      },
      {
        link: "Recently Viewed",
        url: "/#recentlyviewed",
      },
      {
        link: "Faqs",
        url: "#faqs-list",
      },
    ],
  },
  {
    title: "Category",
    footerLinks: [
      {
        link: "Links",
        url: "/category",
      },
      {
        link: "Links",
        url: "/",
      },
      {
        link: "Links",
        url: "/",
      },
      {
        link: "Links",
        url: "/",
      },
    ],
  },
  {
    title: "Item",
    footerLinks: [
      {
        link: "Links",
        url: "/my-product",
      },
      {
        link: "Links",
        url: "/",
      },
      {
        link: "Links",
        url: "/",
      },
      {
        link: "Links",
        url: "/",
      },
    ],
  },
  {
    title: "My account",
    footerLinks: [
      {
        link: "Links",
        url: "/account-detail",
      },
      {
        link: "Links",
        url: "/",
      },
      {
        link: "Links",
        url: "/",
      },
      {
        link: "Links",
        url: "/",
      },
    ],
  },
];

//LANDING HERO LEFT SLIDER DATA
export const homeHeroLeftSlider = [
  {
    image: heroLeftImage1,
  },
  {
    image: heroLeftImage2,
  },
  {
    image: heroLeftImage3,
  },
  {
    image: heroLeftImage2,
  },
];
//LANDING HERO RIGHT SLIDER DATA
export const homeHeroRightSlider = [
  {
    image: heroRightImage1,
  },
  {
    image: heroRightImage2,
  },
  {
    image: heroRightImage3,
  },
  {
    image: heroRightImage2,
  },
];
// SIDEBAR BUTTONS List
export const sideBarBtnList = [
  {
    icon: <HomeIcon />,
    link: "",
    title: "Home",
  },

  {
    icon: <LibrayIcon />,
    link: "library",
    title: "Library",
  },
  {
    icon: <MyReviewIcon />,
    link: "my-reviews",
    title: "My reviews",
  },
  {
    icon: <HistoryIcon />,
    link: "history",
    title: "History",
  },
  {
    icon: <SubscriptionIcon />,
    link: "subscription",
    title: "Subscription",
  },
  {
    icon: <TrendingIcon />,
    link: "trending",
    title: "Trending",
  },
  {
    icon: <MyAccountIcon />,
    link: "account-detail",
    title: "My account",
  },
  {
    icon: <SignOutIcon />,
    link: "sign-out",
    title: "Sign out",
  },
];

//HEADER TABS LINKS
// export const headerBtnList = [
//   {
//     title: "Library",
//     link: "library",
//   },
//   {
//     title: "My reviews",
//     link: "my-reviews",
//   },
//   {
//     title: "History",
//     link: "history",
//   },
//   {
//     title: "Subscription",
//     link: "subscription",
//   },
//   {
//     title: "Trending",
//     link: "trending",
//   },
// ];
//DEFAULT PRODUCT DESCRIPTION
export const defaultPorductDiscription = [
  {
    description:
      "Elit eu maecenas augue lorem malesuada amet mattis risus. Luctus hac at pharetra erat vivamus fusce sit nec. Interdum ornare velit consectetur et purus ultricies egiquet udipiscing volutpat. quaerat explicabo numquam illo nostrum. Incidunt, provident. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Necessitatibus incidunt minima, perferendis alias, ut beatae natus, ea sint ipsum quas assumenda officiis veniam voluptate. Expedita magni dolores aliquid iusto quidem? ",
    threedots: "...",
  },
];

// MOVIE Dropdown Data

export const dropdownData = [
  {
    title: "MOVIES",
    subTitle: [
      {
        title: "NEW Release",
        link: "category",
      },
      {
        title: "Trending",
        link: "category",
      },
      {
        title: "Top 50",
        link: "category",
      },
    ],
  },
  {
    title: "SHOWS",
    subTitle: [
      {
        title: "NEW Release",
        link: "category",
      },
      {
        title: "Trending",
        link: "category",
      },
      {
        title: "Top 50",
        link: "category",
      },
    ],
  },
  // {
  //   title: "MY ACCOUNT",
  //   subTitle: [
  //     {
  //       title: "NEW Release",
  //       link: "",
  //     },
  //     {
  //       title: "Trending",
  //       link: "trending",
  //     },
  //     {
  //       title: "Top 50",
  //       link: "",
  //     },
  //   ],
  // },
];

// FRIENDS LIST
export const friendData = [
  {
    image: profile4,
    title: "Jane Cooper",
  },
  {
    image: profile6,
    title: "Jerome Bell",
  },
  {
    image: profile7,
    title: "Guy Hawkins",
  },
  {
    image: profile9,
    title: "Albert Flores",
  },
  {
    image: profile10,
    title: "Leslie Alexander",
  },
  {
    image: profile11,
    title: "Ronald Richards",
  },
  {
    image: profile4,
    title: "Jane Cooper",
  },
  {
    image: profile6,
    title: "Jerome Bell",
  },
  {
    image: profile7,
    title: "Guy Hawkins",
  },
  {
    image: profile9,
    title: "Albert Flores",
  },
  {
    image: profile10,
    title: "Leslie Alexander",
  },
  {
    image: profile11,
    title: "Ronald Richards",
  },
  {
    image: profile4,
    title: "Jane Cooper",
  },
  {
    image: profile6,
    title: "Jerome Bell",
  },
  {
    image: profile7,
    title: "Guy Hawkins",
  },
  {
    image: profile9,
    title: "Albert Flores",
  },
];

// INFORMATION DROPDOWN DATA
export const informationDropdownData = [
  {
    title: "My communities",
    icon: <MyCommunitiesIcon />,
    subTitle: [
      {
        icon: <AnnouncementIcon />,
        title: "announcements",
      },
      {
        icon: <ResourceIcon />,
        title: "resources",
      },
    ],
  },

  {
    title: "My friends",
    icon: <MyFriendsIcon />,
    subTitle: [
      {
        icon: <FriendIcon />,
        title: "Friends",
      },
      {
        image: nitroIcon,
        title: "Nitro",
      },
      {
        icon: <ShopeIcon />,
        title: " Shop",
      },
    ],
  },
];
