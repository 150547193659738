import React, { useEffect, useState } from "react";
import { friendData } from "../common/Helper";
import { RxCross2 } from "react-icons/rx";
import { useStateProvider } from "./StateContext";
import { informationDropdownData } from "../common/Helper";
import { DropDownIcon } from "../common/Icon";

const MyFriendsSidebar = () => {
  const { setShowFriendData, setShowData } = useStateProvider();

  // Dropdown State Management
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [selectedOption, setSelectedOption] = useState();
  const [friendScrollbarHeight, setFriendScrollbarHeight] = useState(); // Initial height

  const handleDropdownToggle = (index) => {
    setDropdownOpen((prevState) => (prevState === index ? null : index));
  };

  // Update the height dynamically when dropdownOpen changes
  useEffect(() => {
    setFriendScrollbarHeight(calculateFriendScrollbarHeight());
  }, [dropdownOpen]);

  // Function to calculate friendScrollbarHeight based on dropdown state
  const calculateFriendScrollbarHeight = () => {
    if (dropdownOpen === 0) {
      return "100%"; // First dropdown is open
    } else if (dropdownOpen === 1) {
      return "100%"; // Second dropdown is open
    } else {
      return "100%"; // No dropdown is open
    }
  };

  return (
    <>
      {" "}
      <div className=" bg-[#141210] h-screen  min-[1440px]:h-[calc(100vh-76px)] mb-12 w-[205px] md:w-[241px] md:mb-0 backdrop-blur-[136.5px] pt-10 lg:pt-5 absolute top-0  left-[173px] 2sm:left-[190px] min-[1440px]:left-[190px]">
        <button
          aria-label="Closelink"
          className="font-semibold text-white text-[30px] min-[1440px]:hidden absolute top-1 right-[10px]"
          onClick={() => {
            setShowData(false);
            setShowFriendData(false);
          }}>
          <RxCross2 />
        </button>
        <div className=" pt-3 lg:pt-8 min-[1440px]:pt-0">
          {informationDropdownData.map((data, index) => (
            <div
              key={index}
              onClick={() => handleDropdownToggle(index)}
              className="cursor-pointer mb-1">
              <div className="flex flex-col cursor-pointer justify-between relative">
                <div
                  className={`flex items-center justify-between px-3 py-2.5 duration-300 information_dropdown_hover  opacity-70 hover:opacity-100 ${
                    dropdownOpen === index
                      ? "information_dropdown_active !opacity-100"
                      : ""
                  }`}>
                  <div className="flex gap-2 items-center">
                    <span>{data.icon}</span>
                    <p className="md:pl-3 ff_poppins text-[16px] font-normal text-white duration-300">
                      {data.title}
                    </p>
                  </div>
                  <span
                    className={`duration-200  ${
                      dropdownOpen === index ? "rotate-[180deg]" : ""
                    }`}>
                    <span className="block">
                      <DropDownIcon />
                    </span>
                  </span>
                </div>
                {dropdownOpen === index && (
                  <div className="cursor-pointer overflow-hidden  pt-2">
                    <div className="overflow-y-auto h-[100%]">
                      {data.subTitle.map((obj, subIndex) => (
                        <div key={subIndex}>
                          <button
                            className={`ff_poppins flex items-center text-[16px] text-start w-full  font-normal text-white opacity-70 hover:opacity-100   py-1 px-3 duration-300 my-[2px] `}
                            onClick={() => {
                              setSelectedOption(false);
                              setShowFriendData(false);
                              setShowData(false);
                            }}>
                            <span className="pr-4">
                              {obj.icon && typeof obj.icon === "object" ? (
                                obj.icon
                              ) : obj.image ? (
                                <img src={obj.image} alt="nitro-img" />
                              ) : null}
                            </span>
                            {obj.title}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
          <span className="opacity-[0.05] bg-white h-[0.7px] w-full block"></span>
          <div className="!overflow-y-hidden  pb-3">
            <div className="py-4  px-3 md:px-5  flex items-center justify-between">
              <h5 className="ff_poppins font-normal text-sm text-white opacity-70">
                DIRECT MESSAGES
              </h5>
              <p className="ff_poppins font-medium text-base text-white opacity-70">
                +
              </p>
            </div>

            <div
              className={`overflow-y-auto h-[${calculateFriendScrollbarHeight()}]  friend_scrollbar ${
                dropdownOpen === 0
                  ? "h-[calc(100vh-280px)]  min-[1440px]:h-[calc(100vh-320px)]"
                  : dropdownOpen === 1
                  ? "h-[calc(100vh-320px)] min-[1440px]:h-[calc(100vh-360px)]"
                  : "h-[calc(100vh-200px)] min-[1440px]:h-[calc(100vh-250px)]"
              }
              `}>
              {friendData.map((value, index) => {
                return (
                  <div
                    onClick={() => {
                      setShowData(false);
                      setShowFriendData(false);
                    }}
                    className=" opacity-70 hover:opacity-100 hover:bg-[rgba(255,_255,_255,_0.08)] duration-300 w-full"
                    key={index}>
                    <div className="flex items-center py-1 px-3 md:px-5 my-1 ">
                      <img
                        className="w-full max-w-[30px] h-[30px] rounded-full"
                        src={value.image}
                        alt="friend-img"
                      />
                      <p className="ff_poppins text-[16px] font-medium text-white opacity-70 pl-2.5">
                        {value.title}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyFriendsSidebar;
